import { Component, inject } from '@angular/core';
import { ILucePendingUserInformation } from '../../../../interfaces/users.interfaces';
import {
  AbstractOverviewComponent,
  overviewComponentParams,
} from '../../../abstracts/steps/overview/overview.component';
import { UserAddService } from '../../user-add.service';

const { selector, templateUrl, standalone, imports } = overviewComponentParams;
@Component({
  selector: selector + '[data-service="UserAddService"]',
  templateUrl,
  standalone,
  imports,
})
export class OverviewComponent extends AbstractOverviewComponent<UserAddService> {
  protected service = inject(UserAddService);

  protected async submit(): Promise<void> {
    const service = this.service as UserAddService;
    const { email, domains, role, permissions, expirationDate } =
      service.formService.getForm();
    const lucePendingUserInformation: ILucePendingUserInformation = {
      email,
      domains,
      role,
      permissions: permissions + 'n',
      expirationDate: expirationDate ?? undefined,
    };
    const newUser = await service.dataService.submitForm({
      lucePendingUserInformation,
    });
    if (newUser) return;
    this.messageService.add({
      severity: 'success',
      summary: this.translocoService.translate('u.success'),
    });
    this.ref.close(newUser);
  }
}

import { inject, Injectable } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import {
  IDomainsData,
  IUserDetail,
  IUserPermission,
  IUserRole,
  IUsersList,
} from '../interfaces/users.interfaces';

@Injectable({
  providedIn: 'root',
})
export class UsersListDataService {
  private readonly httpService: HttpService = inject(HttpService);

  async getUsersList(): Promise<IUsersList[]> {
    return this.httpService.fetchData<IUsersList[]>(
      '/users',
      'Failed to fetch users list',
    );
  }

  async getUserDetails(userId: number): Promise<IUserDetail> {
    return this.httpService.fetchData(
      `/users/details/${userId}`,
      `Failed to fetch user details for user: ${userId}`,
    );
  }

  retrieveUserRoleAndSubordinateRoles(): Promise<IUserRole[]> {
    return this.httpService.fetchData<IUserRole[]>(
      '/users/roles',
      'Failed to fetch user roles',
    );
  }

  getPermissionsByRole(roleAlias: string): Promise<IUserPermission> {
    return this.httpService.fetchData<IUserPermission>(
      `/users/permissions/${roleAlias}`,
      `Failed to fetch permissions by role for role alias: ${roleAlias}`,
    );
  }

  getUserDomains(): Promise<IDomainsData[]> {
    return this.httpService.fetchData<IDomainsData[]>(
      '/users/domains',
      'Failed to fetch user domains',
    );
  }

  getDomainById(id: number): Promise<IDomainsData> {
    return this.httpService.fetchData<IDomainsData>(
      `/domains/${id}`,
      'Failed to fetch user domains',
    );
  }
}

import { Component, inject } from '@angular/core';
import {
  AbstractUserExpirationComponent,
  userExpirationComponentParams,
} from '../../../abstracts/steps/user-expiration/user-expiration.component';
import { UserAddService } from '../../user-add.service';

const { selector, templateUrl, standalone, imports } =
  userExpirationComponentParams;

@Component({
  selector: selector + '[data-service="UserAddService"]',
  templateUrl,
  standalone,
  imports,
})
export class UserExpirationComponent extends AbstractUserExpirationComponent<UserAddService> {
  protected service = inject(UserAddService);
}

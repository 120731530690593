import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { provideTranslocoScope, TranslocoService } from '@jsverse/transloco';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import {
  catchError,
  debounceTime,
  Observable,
  of,
  Subject,
  switchMap,
} from 'rxjs';
import { AuthService } from 'src/app/components/auth/auth.service';
import {
  MainDomainsService,
  WorkingDomainService,
} from 'src/app/components/auth/services';
import { Domain, UserFromBackend } from 'src/app/components/auth/types';
import { WorkingDomainSelectionComponent } from 'src/app/components/working-domain-selection/working-domain-selection.component';
import { Login } from 'src/app/interfaces/login';
import { FirstLetterCapital } from 'src/app/services/first.capital.case.pipe';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [
    provideTranslocoScope({ scope: 'auth', alias: 'a' }),
    provideTranslocoScope({ scope: 'http-error', alias: 'he' }),
  ],
})
export class LoginComponent implements OnInit {
  hide = false;
  loginSubject = new Subject<Login>();

  readonly loginForm = new FormGroup({
    email: new FormControl('', {
      validators: [
        Validators.required,
        Validators.email,
        Validators.pattern(/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/),
      ],
    }),
    password: new FormControl('', {
      validators: [Validators.required, Validators.minLength(4)],
    }),
  });

  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private firstLetterCapital: FirstLetterCapital,
    private readonly translocoService: TranslocoService,
    private readonly dialogService: DialogService,
    private readonly mainDomainService: MainDomainsService,
    private readonly messageService: MessageService,
    private readonly workingDomainService: WorkingDomainService,
  ) {}

  ngOnInit(): void {
    localStorage.clear();

    this.loginSubject
      .pipe(
        debounceTime(500),
        switchMap((login) => {
          return this.logIn(login);
        }),
      )
      .subscribe((userFromBackend) => {
        if (userFromBackend) {
          this.authService.processUserLogin(userFromBackend);

          const mainDomains = JSON.parse(
            this.mainDomainService.getFromLocalStorage(),
          ) as Domain[];
          if (mainDomains.length > 1) {
            this.showWorkingDomainSelection();
          } else {
            this.workingDomainService.storeInLocalStorage(mainDomains[0]);
            this.redirectToRedirectUrl();
          }
        }
      });
  }

  private logIn(login: Login): Observable<UserFromBackend | null> {
    return this.authService.logInUser(login).pipe(
      catchError((error) => {
        this.messageService.add({
          severity: 'error',
          detail: this.firstLetterCapital.transform(
            this.translocoService.translate('he.' + error.status),
          ),
        });

        return of(null);
      }),
    );
  }

  onSubmit(): void {
    this.loginSubject.next({
      email: this.email.value,
      password: this.password.value,
    });
  }

  showWorkingDomainSelection(): void {
    const ref: DynamicDialogRef = this.dialogService.open(
      WorkingDomainSelectionComponent,
      {
        header: this.firstLetterCapital.transform(
          this.translocoService.translate('a.select_working_domain'),
        ),
        closable: false,
        style: {
          'min-width': '30vh',
        },
      },
    );

    ref.onClose.subscribe(async () => {
      this.redirectToRedirectUrl();
    });
  }

  async redirectToRedirectUrl(): Promise<void> {
    const redirectUrl: string =
      this.route.snapshot.queryParams?.['redirectUrl'];
    if (redirectUrl) {
      await this.router.navigate([redirectUrl]);
    } else {
      await this.router.navigate(['/park']);
    }
  }

  get email(): FormControl<string> {
    return this.loginForm.get('email') as FormControl<string>;
  }

  get password(): FormControl<string> {
    return this.loginForm.get('password') as FormControl<string>;
  }
}

import { TitleCasePipe } from '@angular/common';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  TRANSLOCO_SCOPE,
  TranslocoScope,
  TranslocoService,
  provideTranslocoScope,
} from '@jsverse/transloco';
import { MenuItem } from 'primeng/api';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { filter, switchMap } from 'rxjs';
import { WorkingDomainService } from 'src/app/components/auth/services';

const ROUTE_DATA_BREADCRUMB: string = 'breadcrumb';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  standalone: true,
  imports: [BreadcrumbModule],
  providers: [
    provideTranslocoScope({ scope: ROUTE_DATA_BREADCRUMB }),
    TitleCasePipe,
  ],
  encapsulation: ViewEncapsulation.None,
})
export class BreadcrumbComponent implements OnInit {
  breadcrumbs: MenuItem[] = [];
  home = {
    icon: 'pi pi-home',
    url: 'park',
    target: '_self',
  } as MenuItem;

  constructor(
    @Inject(TRANSLOCO_SCOPE) private scope: TranslocoScope,
    private readonly translocoService: TranslocoService,
    private readonly titleCasePipe: TitleCasePipe,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly workingDomainService: WorkingDomainService,
  ) {}

  ngOnInit(): void {
    const workingDomain = this.workingDomainService.getDomainSubjectAsValue()!;
    this.home = {
      ...this.home,
      label: `${workingDomain.label}`,
    };
    this.updateBreadcrumbs();
    this.handleNavigationEnd();
  }

  private handleNavigationEnd() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        switchMap(async () => this.updateBreadcrumbs()),
      )
      .subscribe();
  }

  private updateBreadcrumbs(): void {
    this.breadcrumbs = this.createBreadcrumbs(this.activatedRoute.root);
  }

  private createBreadcrumbs(
    route: ActivatedRoute,
    url: string = '',
    breadcrumbs: MenuItem[] = [],
  ): MenuItem[] {
    const children: ActivatedRoute[] = route.children;
    if (children.length === 0) return breadcrumbs;
    children.forEach((child) => this.addBreadcrumb(child, url, breadcrumbs));
    return breadcrumbs;
  }

  private addBreadcrumb(
    child: ActivatedRoute,
    url: string,
    breadcrumbs: MenuItem[],
  ) {
    const routeURL: string = this.getRouteURL(child);
    const newUrl: string = routeURL ? `${url}/${routeURL}` : url;
    const label: string = this.getLabel(child, routeURL);

    if (routeURL) breadcrumbs.push({ label, url: newUrl, target: '_self' });

    this.createBreadcrumbs(child, newUrl, breadcrumbs);
  }

  private getRouteURL(child: ActivatedRoute): string {
    return child.snapshot.url.map((segment) => segment.path).join('/');
  }

  private getLabel(child: ActivatedRoute, routeURL: string): string {
    if (
      child.snapshot.data[ROUTE_DATA_BREADCRUMB] !== undefined &&
      child.snapshot.data[ROUTE_DATA_BREADCRUMB] !== ''
    ) {
      routeURL = child.snapshot.data[ROUTE_DATA_BREADCRUMB];
    }

    this.translocoService
      .selectTranslate(routeURL, {}, this.scope)
      .subscribe((res: string) => {
        if (!res.includes(ROUTE_DATA_BREADCRUMB)) {
          routeURL = this.titleCasePipe.transform(res);
        }
      });

    return routeURL;
  }
}

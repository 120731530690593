import {
  AbstractControl,
  ValidationErrors,
  AsyncValidatorFn,
} from '@angular/forms';

export class CheckPasswordValidator {
  static passwordCompare(source: string, target: string): AsyncValidatorFn {
    return async (
      control: AbstractControl,
    ): Promise<ValidationErrors | null> => {
      control.parent?.get(target)!.markAsTouched();

      const result =
        control.parent?.get(source)!.value !==
        control.parent?.get(target)!.value
          ? { passwordMismatch: true }
          : null;
      return result;
    };
  }
}

import { Component, inject } from '@angular/core';
import {
  AbstractUserExpirationComponent,
  userExpirationComponentParams,
} from '../../../abstracts/steps/user-expiration/user-expiration.component';
import { UserEditService } from '../../user-edit.service';

const { selector, templateUrl, standalone, imports } =
  userExpirationComponentParams;

@Component({
  selector: selector + '[data-service="UserEditService"]',
  templateUrl,
  standalone,
  imports,
})
export class UserExpirationComponent extends AbstractUserExpirationComponent<UserEditService> {
  protected service = inject(UserEditService);
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, mergeMap, tap } from 'rxjs/operators';
import { EnvironmentLoaderService } from 'src/app/environment-loader.service';
import {
  AuthUserService,
  LangService,
  MainDomainsService,
  TokensService,
  WorkingDomainService,
} from './services';
import { AuthUser, Domain, Lang, Tokens, UserFromBackend } from './types';
import { PermissionService } from 'src/app/services/permissions.service';
import { HttpAbortService } from 'src/app/shared/http-abort/http-abort.service';
import { MessageService } from 'primeng/api';
import { capitalize } from 'src/app/services/first.capital.case.pipe';
import { TranslocoService } from '@jsverse/transloco';
import { Login } from 'src/app/interfaces/login';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly baseUrl: string =
    this.envService.getEnvConfig().backend.baseUrl;

  readonly isLoggedIn = new BehaviorSubject(false);

  refreshTokenTimeout?: NodeJS.Timeout;

  constructor(
    private readonly http: HttpClient,
    private readonly router: Router,
    private readonly envService: EnvironmentLoaderService,
    private readonly mainDomainsService: MainDomainsService,
    private readonly workingDomainService: WorkingDomainService,
    private readonly langService: LangService,
    private readonly tokensService: TokensService,
    private readonly authUserService: AuthUserService,
    private readonly permissionService: PermissionService,
    private readonly abortHttpService: HttpAbortService,
    private readonly messageService: MessageService,
    private readonly translocoService: TranslocoService,
  ) {}

  logInUser(user: Login): Observable<UserFromBackend> {
    return this.http.post<UserFromBackend>(`${this.baseUrl}/login`, user);
  }

  logOutUser(redirectUrl?: string, sessionExpired?: boolean): Observable<void> {
    this.abortHttpService.abortPendingRequests();
    const refreshToken = this.tokensService.refreshToken.getFromLocalStorage();
    const accessToken = this.tokensService.accessToken.getFromLocalStorage();

    this.clearUserSession();
    this.clearRefreshTokenTimeout();
    this.redirectToLogin(redirectUrl);

    if (sessionExpired) {
      this.messageService.add({
        severity: 'error',
        summary: capitalize(
          this.translocoService.translate('a.session_expired'),
        ),
        detail: this.translocoService.translate('a.please_reconnect'),
        life: 5000,
      });
    }

    return this.performLogout(refreshToken, accessToken).pipe(
      tap(() => window.location.reload()),
      catchError((error) =>
        throwError(() => new Error('Logout failed: ' + error.message)),
      ),
    );
  }

  redirectToLogin(redirectUrl?: string) {
    this.router.navigate(['/login'], {
      queryParams: { redirectUrl: redirectUrl },
    });
  }

  redirectToHome() {
    this.router.navigate(['']);
  }

  validateAccess(
    route: ActivatedRouteSnapshot,
    callback?: (isLoggedIn: boolean) => void,
  ) {
    const isLoggedIn = this.checkTokensAndPermission(route);
    this.isLoggedIn.next(isLoggedIn);
    callback?.(isLoggedIn);
    return isLoggedIn;
  }

  processUserLogin(userFromBackend: UserFromBackend): void {
    if (!userFromBackend.refreshToken || !userFromBackend.accessToken)
      throw new Error('No tokens provided');

    this.storeUserDetails(userFromBackend);
    this.setRefreshTokenExpirationHandler(userFromBackend.refreshToken);
    this.langService.adjustSettings(userFromBackend.locale);
  }

  private checkTokensAndPermission(route: ActivatedRouteSnapshot): boolean {
    if (!this.hasValidTokens() || !this.hasValidWorkingDomain()) return false;

    if (!this.hasRequiredPermission(route) && !this.hasRequiredTabs(route))
      this.router.navigate(['/unauthorized']);

    return true;
  }

  private hasValidTokens(): boolean {
    const refreshToken = this.tokensService.refreshToken.getFromLocalStorage();
    const accessToken = this.tokensService.accessToken.getFromLocalStorage();
    return (
      !!accessToken &&
      !!refreshToken &&
      !this.tokensService.isTokenExpired(refreshToken)
    );
  }

  private hasValidWorkingDomain(): boolean {
    const workingDomain = this.workingDomainService.getFromLocalStorage();
    return !!workingDomain;
  }

  private hasRequiredPermission(route: ActivatedRouteSnapshot): boolean {
    const permission = route.data?.['permission'];
    if (!permission) return true;
    return this.permissionService.hasPermission(permission);
  }

  private hasRequiredTabs(route: ActivatedRouteSnapshot): boolean {
    const tabs = route.data?.['tabs'];
    return !!tabs;
  }

  private setRefreshTokenExpirationHandler(refreshToken: string) {
    const expirationDate = this.tokensService.tokenExpirationDate(refreshToken);
    // Already expired or invalid
    if (!expirationDate) {
      this.logOutUser(this.router.url, true);
    } else {
      const expiresIn = expirationDate.getTime() - Date.now();
      this.clearRefreshTokenTimeout();
      this.refreshTokenTimeout = setTimeout(() => {
        this.logOutUser(this.router.url, true);
      }, expiresIn);
    }
  }

  private clearRefreshTokenTimeout() {
    if (this.refreshTokenTimeout) {
      clearTimeout(this.refreshTokenTimeout);
    }
  }

  private storeUserDetails(userFromBackend: UserFromBackend): void {
    [
      this.authUserService,
      this.mainDomainsService,
      this.langService,
      this.tokensService,
    ].forEach((service) => {
      const data = service.getByUserFromBackend(userFromBackend);
      service.storeInLocalStorage(
        data as (Tokens & Domain[] & Lang) & AuthUser,
      );
    });
  }

  private clearUserSession(): void {
    [
      this.authUserService,
      this.langService,
      this.tokensService,
      this.mainDomainsService,
      this.workingDomainService,
    ].forEach((service) => {
      service.removeFromLocalStorage();
    });
  }

  private performLogout(
    refreshToken: string,
    accessToken: string,
  ): Observable<void> {
    return this.http
      .post<void>(`${this.baseUrl}/logout`, { token: refreshToken })
      .pipe(
        mergeMap(() =>
          this.http.post<void>(`${this.baseUrl}/logout`, {
            token: accessToken,
          }),
        ),
      );
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { Dropdown, DropdownModule } from 'primeng/dropdown';
import { MainDomainsService, WorkingDomainService } from '../auth/services';

import { ButtonModule } from 'primeng/button';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Domain } from '../auth/types';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-working-domain-selection',
  templateUrl: 'working-domain-selection.component.html',
  imports: [DropdownModule, ButtonModule],
  providers: [DialogService],
  standalone: true,
})
export class WorkingDomainSelectionComponent implements OnInit {
  @ViewChild('workingDomainDropdown') workingDomainDropdown: Dropdown =
    {} as Dropdown;

  mainDomains!: Domain[];

  constructor(
    private readonly workingDomainService: WorkingDomainService,
    private readonly mainDomainsService: MainDomainsService,
    private route: ActivatedRoute,
    private readonly router: Router,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
  ) {}

  ngOnInit() {
    this.mainDomains = this.getMainDomains();
  }

  async selectWorkingDomain(workingDomain: Domain) {
    this.workingDomainService.storeInLocalStorage(workingDomain);
    this.workingDomainService.setDomainSubject(workingDomain);
    if (this.route.snapshot.queryParams?.['redirectUrl']) {
      await this.router.navigate([this.route.snapshot.queryParams]);
    } else {
      await this.router.navigate(['/park']);
    }
    this.ref.close();
  }

  getMainDomains(): Domain[] {
    const mainDomainsStr = this.mainDomainsService.getFromLocalStorage();
    const domains = JSON.parse(mainDomainsStr) as Domain[];
    return domains.sort((a, b) => a.label.localeCompare(b.label));
  }
}

import { Injectable } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { UserFormService } from '../abstracts/user-form.service';

type UserEditForm = {
  domains: number[];
  selectedDomains: number[];
  selectedDomainTree: TreeNode[];
  role: string;
  permissions: string;
  expirationDate: string | null;
};

@Injectable({
  providedIn: 'root',
})
export class UserEditFormService extends UserFormService<UserEditForm> {
  defaultForm: UserEditForm = {
    domains: [],
    selectedDomains: [],
    selectedDomainTree: [],
    role: '',
    permissions: '',
    expirationDate: null,
  };

  form: UserEditForm = this.defaultForm;
}

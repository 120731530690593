import { Component, inject } from '@angular/core';
import {
  AbstractPermissionsSelectorComponent,
  permissionsSelectorComponentParams,
} from '../../../abstracts/steps/permissions-selector/permissions-selector.component';
import { UserAddService } from '../../user-add.service';

const { selector, templateUrl, standalone, imports } =
  permissionsSelectorComponentParams;

@Component({
  selector: selector + '[data-service="UserAddService"]',
  templateUrl,
  standalone,
  imports,
})
export class PermissionsSelectorComponent extends AbstractPermissionsSelectorComponent<UserAddService> {
  protected service = inject(UserAddService);
}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export abstract class UserFormService<Form> {
  abstract readonly defaultForm: Form;
  abstract form: Form;

  getForm(): Form {
    return this.form;
  }

  setForm(form: Form): void {
    this.form = form;
  }

  resetForm(): void {
    this.form = this.defaultForm;
  }
}

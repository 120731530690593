import { HttpClient } from '@angular/common/http';
import { Injectable, NgModule } from '@angular/core';
import {
  TRANSLOCO_LOADER,
  TRANSLOCO_MISSING_HANDLER,
  Translation,
  TranslocoLoader,
  TranslocoModule,
  getBrowserLang,
  provideTransloco,
} from '@jsverse/transloco';
import { provideTranslocoMessageformat } from '@jsverse/transloco-messageformat';
import { provideTranslocoPersistTranslations } from '@jsverse/transloco-persist-translations';
import { Observable } from 'rxjs';
import { CustomMissingTranslationHandler } from './services/missing-translation-handler';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private readonly http: HttpClient) {}

  getTranslation(langPath: string): Observable<Translation> {
    return this.http.get<Translation>(`./assets/i18n/${langPath}.json`);
  }
}

@NgModule({
  exports: [TranslocoModule],
  providers: [
    provideTransloco({
      config: {
        availableLangs: [
          { id: 'en', label: 'english' },
          { id: 'fr', label: 'french' },
        ],
        defaultLang: getBrowserLang(),
        fallbackLang: ['fr', 'en'],
        reRenderOnLangChange: true,
        missingHandler: {
          logMissingKey: true,
          useFallbackTranslation: true,
        },
        failedRetries: 5,
        prodMode: true,
        flatten: {
          aot: true,
        },
      },
      loader: TranslocoHttpLoader,
    }),
    { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader },
    {
      provide: TRANSLOCO_MISSING_HANDLER,
      useClass: CustomMissingTranslationHandler,
    },

    provideTranslocoPersistTranslations({
      loader: TranslocoHttpLoader,
      storage: { useValue: localStorage },
    }),
    provideTranslocoMessageformat(),
  ],
})
export class TranslocoRootModule {}

import { Injectable } from '@angular/core';
import { UserDataService } from '../abstracts/user-data.service';
import { IUserEdited } from '../../interfaces/users.interfaces';

type FormData = {
  userId: number;
  userInformationsToEdit: UserInformationsToEdit;
};

export type UserInformationsToEdit = {
  domains: number[];
  role: string;
  permissions: string;
  expirationDate?: string | null;
};

@Injectable({
  providedIn: 'root',
})
export class UserEditDataService extends UserDataService<
  FormData,
  IUserEdited
> {
  protected readonly serviceName: string = 'UserEditService';
  userId!: number;
  userInformationsToEdit!: UserInformationsToEdit;
  submitForm({
    userId,
    userInformationsToEdit,
  }: FormData): Promise<IUserEdited> {
    return this.httpService.putData<IUserEdited>(
      `/users/${userId}`,
      userInformationsToEdit,
      this.translocoService.translate('u.error.edit'),
    );
  }
}

<form [formGroup]="formGroup">
  <div class="flex flex justify-content-center w-full pt-8">
    <p-treeTable
      #pt
      [columns]="permissionCols"
      [value]="permissionsTreeNodes"
      selectionMode="checkbox"
      [(selection)]="selectedPermissionsTreeNodes"
      (onNodeSelect)="permissionSelect($event)"
      (onNodeUnselect)="permissionUnselect($event)"
      (onHeaderCheckboxToggle)="onHeaderCheckboxToggleAllPermssions($event)"
      scrollHeight="250px"
      [scrollable]="true"
    >
      <ng-template pTemplate="caption">
        <div
          class="flex flex-wrap justify-content-between align-items-center gap-4"
        >
          <input
            type="text"
            pInputText
            [placeholder]="'u.permission_placeholder' | transloco | capitalcase"
            (input)="pt.filterGlobal($any($event.target).value, 'contains')"
          />
          <div class="flex align-items-center gap-2">
            <p-treeTableHeaderCheckbox></p-treeTableHeaderCheckbox>
            <span
              class="white-space-nowrap"
              *ngIf="isAllPermissionsChecked(); else permissionSelect"
            >
              {{ "u.permissions_unselect_all" | transloco | capitalcase }}
            </span>
            <ng-template #permissionSelect>
              <span class="white-space-nowrap">
                {{ "u.permissions_select_all" | transloco | capitalcase }}
              </span>
            </ng-template>
          </div>
        </div>
      </ng-template>

      <ng-template
        pTemplate="body"
        let-rowNode
        let-rowData="rowData"
        let-columns="columns"
      >
        <tr>
          <td *ngFor="let col of columns; let i = index">
            <p-treeTableToggler
              [rowNode]="rowNode"
              *ngIf="i === 0"
            ></p-treeTableToggler>
            <p-treeTableCheckbox
              [value]="rowNode"
              *ngIf="i === 0"
            ></p-treeTableCheckbox>
            {{
              "u.permissions." + rowData[col.field] | transloco | capitalcase
            }}
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="emptymessage">
        <tr>
          <td>
            {{ "u.permission_no_found" | transloco | capitalcase }}
          </td>
        </tr>
      </ng-template>
    </p-treeTable>
  </div>
  <div class="flex justify-content-between w-full pt-4">
    <p-button
      label="{{ 'u.back' | transloco | capitalcase }}"
      (onClick)="prevStep()"
      icon="pi pi-angle-left"
    ></p-button>
    <p-button
      label="{{ 'u.next' | transloco | capitalcase }}"
      icon="pi pi-angle-right"
      iconPos="right"
      [disabled]="!permissionsControl.valid"
      (onClick)="nextStep()"
    ></p-button>
  </div>
</form>
<p-toast
  key="userForm"
  [breakpoints]="{ '577px': { width: '100%', right: '0', left: '0' } }"
  [showTransformOptions]="'translateY(100%)'"
  [showTransformOptions]="'translateX(100%)'"
></p-toast>

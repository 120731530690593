<div class="w-full h-auto overflow-hidden border-round bg-white shadow-2 pl-5">
  <h2 class="relative text-2xl">
    {{ "cl.changelog_title" | transloco | capitalcase }}
  </h2>
  <div class="w- px-8 pt-4 overflow-y-auto release">
    <p-timeline [value]="changelog">
      <ng-template pTemplate="content" let-changelog>
        <div class="flex flex-column">
          <span class="text-2xl font-bold text-black-600">
            {{ changelog.version }}
          </span>
          <div *ngFor="let change of changelog.changes" class="flex gap-3 py-4">
            <p-tag
              styleClass="{{ change.tag | releaseTagDecoder }}_tag"
              value="{{
                'cl.' + (change.tag | releaseTagDecoder)
                  | transloco
                  | capitalcase
              }}"
            ></p-tag>
            <div class="text-base pt-1" *ngFor="let msg of change.message">
              {{ msg.description }}
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="opposite" let-event>
        <small class="text-base p-text-secondary">{{
          event.releaseDate | date: "dd/MM/YYYY"
        }}</small>
      </ng-template>
    </p-timeline>
  </div>
</div>
<router-outlet />

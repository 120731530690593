import { Component, inject } from '@angular/core';
import {
  AbstractRolesSelectorComponent,
  rolesSelectorComponent,
} from '../../../abstracts/steps/roles-selector/roles-selector.component';
import { UserAddService } from '../../user-add.service';

const { selector, templateUrl, standalone, imports } = rolesSelectorComponent;

@Component({
  selector: selector + '[data-service="UserAddService"]',
  templateUrl,
  standalone,
  imports,
})
export class RolesSelectorComponent extends AbstractRolesSelectorComponent<UserAddService> {
  protected service = inject(UserAddService);

  protected override fillForm(): void {
    return;
  }
}

<div
  class="flex flex-column gap-2 h-auto"
  *ngIf="permissionService.hasPermission(['ADMIN_R_USER']); else unauthorized"
>
  <p-treeTable
    #tt
    [value]="usersList"
    [columns]="cols"
    [rows]="10"
    [paginator]="true"
    [showCurrentPageReport]="true"
    [globalFilterFields]="['firstname', 'lastname', 'email', 'roles']"
    [currentPageReportTemplate]="
      '{first}' +
      ' ' +
      ('u.paginator.to' | transloco) +
      ' ' +
      '{last}' +
      ' ' +
      ('u.paginator.of' | transloco) +
      ' ' +
      '{totalRecords}' +
      ' ' +
      ('u.paginator.users' | transloco)
    "
    styleClass="p-treetable-gridlines"
  >
    <ng-template pTemplate="caption">
      <div class="flex justify-content-between">
        <h2 class="table-header">
          {{ "u.users_list" | transloco | capitalcase }}
        </h2>
        <div class="flex align-items-center gap-2">
          <div class="flex justify-content-end align-items-center">
            <div class="p-input-icon-left">
              <i class="pi pi-search"></i>
              <input
                type="text"
                pInputText
                [placeholder]="'u.search' | transloco | capitalcase"
                (input)="tt.filterGlobal($any($event.target).value, 'contains')"
              />
            </div>
          </div>
          <p-button
            [rounded]="true"
            icon="fa-solid fa-user-plus"
            [ariaLabel]="'u.add_new_user'"
            (onClick)="openUserAddForm()"
            [hidden]="!permissionService.hasPermission(['ADMIN_W_USER'])"
          />
        </div>
      </div>
    </ng-template>

    <ng-template pTemplate="header" let-columns>
      <tr>
        <th
          *ngFor="let col of cols"
          id="{{ col.header }}"
          [ttSortableColumn]="col.field!"
        >
          {{ "u." + col.header | transloco | capitalcase }}
          <p-treeTableSortIcon
            *ngIf="col.field !== 'domains' && col.field !== 'permissions_'"
            [field]="col.field"
          ></p-treeTableSortIcon>
        </th>
        <th id="actions" *ngIf="usersList">
          {{ "u.actions" | transloco | capitalcase }}
        </th>
      </tr>
    </ng-template>
    <ng-template
      pTemplate="body"
      let-rowNode
      let-rowData="rowData"
      let-columns="columns"
    >
      <tr>
        <td>
          <span class="font-bold">
            <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
          </span>
          {{ rowData.lastname | capitalcase }}
        </td>
        <td>{{ rowData.firstname | capitalcase }}</td>
        <td>{{ rowData.email }}</td>
        <td>{{ rowData.rolesTranslated | capitalcase }}</td>
        <td>
          <p-button
            icon="pi pi-eye"
            [rounded]="true"
            [text]="true"
            severity="primary"
            (onClick)="openDomainsOverview(rowData.id)"
            [pTooltip]="'u.view_domains' | transloco | capitalcase"
          ></p-button>
        </td>
        <td>
          <p-button
            icon="pi pi-eye"
            [rounded]="true"
            [text]="true"
            severity="primary"
            (onClick)="openPermissionsOverview(rowData.permissions)"
            [pTooltip]="'u.view_permissions' | transloco | capitalcase"
          ></p-button>
        </td>
        <td>
          <p-button
            [pTooltip]="'u.edit_user' | transloco | capitalcase"
            icon="fa-solid fa-user-pen"
            class="mr-2"
            [rounded]="true"
            [ariaLabel]="'u.edit_user'"
            [hidden]="!permissionService.hasPermission(['ADMIN_W_USER'])"
            (onClick)="
              openUserEditForm(rowData.id, rowData.permissions, rowData.roles)
            "
          />
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="7">
          {{ "u.no_result_found" | transloco | capitalcase }}
        </td>
      </tr>
    </ng-template>
  </p-treeTable>
</div>

<p-dialog
  [header]="'u.permissions_' | transloco | capitalcase"
  [(visible)]="visiblePermissions"
  [style]="{ width: '40vw' }"
  [modal]="true"
  [draggable]="false"
>
  <p-tree [value]="permissionsSummaryTreeNodes" class="w-full">
    <ng-template let-node pTemplate="default">
      <strong>{{
        "u.permissions." + node.data.label | transloco | capitalcase
      }}</strong>
    </ng-template>
  </p-tree>
</p-dialog>

<p-dialog
  [header]="'u.domains' | transloco | capitalcase"
  [(visible)]="visibleDomains"
  [modal]="true"
  [draggable]="false"
  [style]="{ width: '40vw' }"
>
  <div *ngFor="let domainList of domainsResumeList" class="w-full mb-1">
    <p-tree [value]="[domainList]" layout="horizontal" class="w-full">
      <ng-template let-node pTemplate="default">
        <strong>{{ node.label }}</strong>
      </ng-template>
    </p-tree>
  </div>
</p-dialog>

<ng-template #unauthorized> <app-unauthorized /> </ng-template>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  EmptyError,
  catchError,
  defaultIfEmpty,
  lastValueFrom,
  of,
} from 'rxjs';
import { EnvironmentLoaderService } from 'src/app/environment-loader.service';
import {
  ILanguage,
  IUserEmail,
  IUserInput,
} from '../interfaces/users.interfaces';

@Injectable({
  providedIn: 'root',
})
export class UsersValidationDataService {
  private readonly baseUrl: string;

  constructor(
    private readonly http: HttpClient,
    private readonly envService: EnvironmentLoaderService,
  ) {
    this.baseUrl = this.envService.getEnvConfig().backend.baseUrl;
  }

  async getEmailFromToken(token: string): Promise<IUserEmail> {
    return lastValueFrom(
      this.http
        .get<IUserEmail>(`${this.baseUrl}/users/validation/${token}`)
        .pipe(
          defaultIfEmpty({} as IUserEmail),
          catchError((error) => {
            if (error instanceof EmptyError) {
              return of({} as IUserEmail);
            }
            throw error;
          }),
        ),
    );
  }

  userValidation(userInput: IUserInput) {
    return lastValueFrom(
      this.http.post<any>(
        `${this.baseUrl}/users/validation/register_luce_user`,
        userInput,
      ),
    );
  }

  getLanguages(): Promise<ILanguage[]> {
    return lastValueFrom(
      this.http.get<ILanguage[]>(`${this.baseUrl}/users/validation/languages`),
    );
  }
}

import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import {
  IDomainsData,
  IUserRole,
  IUserPermission,
} from '../interfaces/users.interfaces';

@Injectable({
  providedIn: 'root',
})
export class UserSharedDataService {
  constructor(private readonly httpService: HttpService) {}

  getUserDomains(): Promise<IDomainsData[]> {
    return this.httpService.fetchData<IDomainsData[]>(
      '/users/domains',
      'Failed to fetch user domains',
    );
  }

  retrieveUserRoleAndSubordinateRoles(): Promise<IUserRole[]> {
    return this.httpService.fetchData<IUserRole[]>(
      '/users/roles',
      'Failed to fetch user roles',
    );
  }

  getPermissionsByRole(roleAlias: string): Promise<IUserPermission> {
    return this.httpService.fetchData<IUserPermission>(
      `/users/permissions/${roleAlias}`,
      'Failed to fetch permissions by role',
    );
  }
}

import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  provideTranslocoScope,
  TranslocoModule,
  TranslocoService,
} from '@jsverse/transloco';
import { MenuItem, TreeNode } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { DialogModule } from 'primeng/dialog';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { TooltipModule } from 'primeng/tooltip';
import { TreeModule } from 'primeng/tree';
import { TreeTableModule } from 'primeng/treetable';
import { ApplicationPipesModule } from 'src/app/app-pipes.module';
import {
  Column,
  IDomainsData,
  IFormatedUser,
  IUserEdited,
  IUsersList,
} from 'src/app/components/users/interfaces/users.interfaces';
import { UsersListDataService } from 'src/app/components/users/user-list/user-list.data.service';
import { IFeaturePermission } from 'src/app/interfaces/IFeaturePermission';
import { featurePermissions } from 'src/app/services/feature-permissions.service';
import { FirstLetterCapital } from 'src/app/services/first.capital.case.pipe';
import { PermissionService } from 'src/app/services/permissions.service';
import { UnauthorizedComponent } from 'src/app/shared/unauthorized/unauthorized.component';
import { UserAddFormComponent } from '../user-forms/user-add/user-add-form.component';
import { UserEditFormComponent } from '../user-forms/user-edit/user-edit-form.component';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
  providers: [
    provideTranslocoScope({ scope: 'users', alias: 'u' }),
    FirstLetterCapital,
    DialogService,
  ],
  standalone: true,
  imports: [
    CommonModule,
    CardModule,
    TreeTableModule,
    ButtonModule,
    TranslocoModule,
    ApplicationPipesModule,
    UnauthorizedComponent,
    InputTextModule,
    TooltipModule,
    DialogModule,
    TreeModule,
  ],
})
export class UsersListComponent implements OnInit, OnDestroy {
  usersList!: TreeNode[];
  users!: IUsersList[];
  selectedUser!: IFormatedUser;
  cols!: Column[];
  loading: boolean = false;
  userConnectedId!: number;
  ref!: DynamicDialogRef | undefined;
  items!: MenuItem[];
  fieldsArray: string[] = [
    'lastname',
    'firstname',
    'email',
    'roles_',
    'domains',
    'permissions_',
  ];
  visibleDomains: boolean = false;
  visiblePermissions: boolean = false;
  permissions!: number;
  permissionsSummaryTreeNodes: TreeNode[] = [];
  domainsResumeList: TreeNode<IDomainsData>[] = [];

  constructor(
    private readonly usersListDataService: UsersListDataService,
    public dialogService: DialogService,
    public permissionService: PermissionService,
    private translocoService: TranslocoService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.userConnectedId = this.getUserIdFromLocalStorage();
    this.usersList = await this.formatUsersList();
    this.cols = this.getFieldsAndHeaders(this.fieldsArray);
  }

  getFieldsAndHeaders(data: string[]) {
    return data.map((value) => {
      // Regex to remove the last character of the string if it is an underscore
      const regex = /_$/;
      const field = value.replace(regex, '');
      return {
        field,
        header: value,
      };
    });
  }

  getUserIdFromLocalStorage(): number {
    const data = localStorage.getItem('authUser');
    return Number(JSON.parse(data!).usersId);
  }

  async getUserChildren(
    userId: number,
    users: IUsersList[],
  ): Promise<IUsersList[]> {
    return users.filter((user) => user.creatorId === userId);
  }

  getUsersTree(users: IUsersList[], userId: number): TreeNode[] {
    return users
      .filter((user) => user.creatorId === userId)
      .map((user) => {
        return {
          label: `${user.lastname} ${user.firstname}`,
          data: {
            id: user.id,
            lastname: user.lastname,
            firstname: user.firstname,
            email: user.email,
            roles: user.roles,
            rolesTranslated: user.rolesTranslated,
            permissions: user.permissions,
          },
          children: this.getUsersTree(users, user.id),
        };
      });
  }

  async formatUsersList() {
    const users = await this.usersListDataService.getUsersList();
    let formattedUsers: IUsersList[] = [];
    if (users) {
      formattedUsers = users.map((user: IUsersList) => {
        return {
          ...user,
          rolesTranslated: user.roles
            .map((role) => this.translocoService.translate('u.roles.' + role))
            .join(', '),
        };
      });
    }
    return this.getUsersTree(formattedUsers, this.userConnectedId);
  }

  openUserAddForm(): void {
    this.ref = this.dialogService.open(UserAddFormComponent, {
      width: '80%',
      minX: 455,
      baseZIndex: 10000,
      maximizable: true,
    });
  }

  async openUserEditForm(
    userId: number,
    permissions: number,
    role: string,
  ): Promise<void> {
    const user = await this.usersListDataService.getUserDetails(userId);
    this.ref = this.dialogService.open(UserEditFormComponent, {
      data: {
        userId,
        userInformationsToEdit: {
          domains: user.domains,
          permissions: permissions,
          role: role,
          expirationDate: user.expirationDate,
        },
      },
      width: '80%',
      minX: 455,
      baseZIndex: 10000,
      maximizable: true,
    });

    this.ref.onClose.subscribe(async (editedUser: IUserEdited) => {
      if (editedUser) {
        const user = this.usersList.find(
          (user: TreeNode) => user.data.id === userId,
        );
        user!.data.rolesTranslated = this.translocoService.translate(
          'u.roles.' + editedUser.role,
        );
        user!.data.roles = [editedUser.role];
        user!.data.permissions = editedUser.permissions;
      }
    });
  }

  async openDomainsOverview(userId: number): Promise<void> {
    const user = await this.usersListDataService.getUserDetails(userId);
    this.domainsResumeList = await this.domainsConvertToTreeNode(user.domains);
    this.visibleDomains = true;
  }

  async openPermissionsOverview(permissions: number): Promise<void> {
    this.permissions = permissions;
    this.permissionsSummaryTreeNodes = this.getPermissionsSummaryTreeNodes();
    this.visiblePermissions = true;
  }

  ngOnDestroy() {
    if (this.ref) {
      this.ref.close();
    }
  }

  async domainsConvertToTreeNode(domains: IDomainsData[]): Promise<TreeNode[]> {
    const domainsMap = new Map<number, IDomainsData>(
      domains.map((domain) => [domain.id, { ...domain, children: [] }]),
    );

    for (const domain of domainsMap.values()) {
      if (domain.domainIdParent !== null) {
        const parentDomain = domainsMap.get(domain.domainIdParent!);
        if (parentDomain) {
          parentDomain.children!.push({
            id: domain.id,
            label: domain.label,
            data: {
              id: domain.id,
              label: domain.label,
            },
          });
        } else if (domain.domainIdParent) {
          const parentDomain = await this.usersListDataService.getDomainById(
            domain.domainIdParent,
          );
          domainsMap.delete(domain.id);
          domainsMap.set(parentDomain.id, {
            ...parentDomain,
            icon: 'pi pi-ban',
            domainIdParent: null,
            children: [
              {
                ...domain,
                data: { id: domain.id, label: domain.label },
                domainIdParent: parentDomain.id,
              },
            ],
          });
          /* domainsMap.set(domain.id, {
            ...domain,
            domainIdParent: null,
          }); */
        }
      }
    }

    const domainNode: TreeNode[] = Array.from(domainsMap.values()).filter(
      (domain) => domain.domainIdParent === null,
    );
    return domainNode;
  }

  private absoluteValueBigInt(value: bigint): bigint {
    return value < 0 ? -value : value;
  }

  private parsePermissions(serializedPermissions: bigint): bigint[] {
    const permissions: bigint[] = [];
    for (
      let permission: bigint = BigInt(1);
      permission <= serializedPermissions && permission > 0;
      permission <<= BigInt(1)
    ) {
      if (permission & serializedPermissions) permissions.push(permission);
    }
    return permissions;
  }

  private getPermissionsSummaryTreeNodes(): TreeNode[] {
    const permissionValue: bigint = this.absoluteValueBigInt(
      BigInt(this.permissions),
    );
    const arrayPermission: bigint[] = this.parsePermissions(permissionValue);

    const filteredFeatures: IFeaturePermission[] = featurePermissions.filter(
      (feature) => {
        return arrayPermission.includes(feature.permission);
      },
    );

    const categoryMap: Map<string, TreeNode> = new Map();
    const result: TreeNode[] = [];

    for (const feature of filteredFeatures) {
      if (!categoryMap.has(feature.parentCategory)) {
        categoryMap.set(feature.parentCategory, {
          data: {
            label: feature.parentCategory,
          },
          children: [],
        });
      }

      const category = categoryMap.get(feature.parentCategory);
      if (category) {
        category.children!.push({
          data: {
            label: feature.label,
            permmission: BigInt(feature.permission),
          },
        });
      }
    }

    categoryMap.forEach((value) => {
      result.push(value);
    });

    return result;
  }
}

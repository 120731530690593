import {
  HttpClient,
  HttpContext,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { lastValueFrom, Observable } from 'rxjs';
import { EnvironmentLoaderService } from '../environment-loader.service';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  private readonly envService: EnvironmentLoaderService = inject(
    EnvironmentLoaderService,
  );
  private readonly http: HttpClient = inject(HttpClient);
  private readonly messageService: MessageService = inject(MessageService);
  private baseUrl: string = this.envService.getEnvConfig().backend.baseUrl;

  setBaseUrl(url: string): this {
    this.baseUrl = url;
    return this;
  }

  private getFullUrl(url: string): string {
    return this.baseUrl + url;
  }

  async fetchData<T>(
    url: string,
    errorMessage: string,
    options?: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
      context?: HttpContext;
      observe?: 'body';
      params?:
        | HttpParams
        | {
            [param: string]:
              | string
              | number
              | boolean
              | ReadonlyArray<string | number | boolean>;
          };
      reportProgress?: boolean;
      responseType?: 'json';
      withCredentials?: boolean;
    },
  ): Promise<T> {
    return this.handleRequest<T>(
      () => this.http.get<T>(this.getFullUrl(url), options),
      errorMessage,
    );
  }

  async postData<T>(
    url: string,
    data: object,
    errorMessage: string,
  ): Promise<T> {
    return this.handleRequest<T>(
      () => this.http.post<T>(this.getFullUrl(url), data),
      errorMessage,
    );
  }

  async putData<T>(
    url: string,
    data: object,
    errorMessage: string,
    headers?: HttpHeaders,
  ): Promise<T> {
    const options = this.createOptions(headers);
    return this.handleRequest<T>(
      () => this.http.put<T>(this.getFullUrl(url), data, options),
      errorMessage,
    );
  }

  private createOptions(headers?: HttpHeaders): { headers?: HttpHeaders } {
    return headers ? { headers } : {};
  }

  private async handleRequest<T>(
    requestFn: () => Observable<T>,
    errorMessage: string,
  ): Promise<T> {
    try {
      const response = await lastValueFrom(requestFn());
      if (response === null || response === undefined) return response as T;
      return response;
    } catch (error) {
      console.log(errorMessage);
      throw error;
    }
  }
}

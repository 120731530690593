<form [formGroup]="formGroup">
  <div class="flex flex justify-content-center w-full pt-8">
    <div class="grid col-12">
      <p-treeTable
        #ttd
        [columns]="domainsCols"
        [value]="domainsList"
        selectionMode="checkbox"
        [(selection)]="selectedDomains"
        (onNodeSelect)="domainSelectionChange($event, true)"
        (onNodeUnselect)="domainSelectionChange($event, false)"
        (onHeaderCheckboxToggle)="onHeaderCheckboxToggleAllDomains($event)"
        [scrollable]="true"
        scrollHeight="250px"
      >
        <ng-template pTemplate="caption">
          <div class="flex justify-content-between">
            <input
              type="text"
              pInputText
              [placeholder]="'u.domains_placeholder' | transloco | capitalcase"
              (input)="ttd.filterGlobal($any($event.target).value, 'contains')"
            />
            <div class="flex align-items-center gap-2">
              <p-treeTableHeaderCheckbox></p-treeTableHeaderCheckbox>
              <span
                class="whitespace-nowrap"
                *ngIf="isCheckedDomains; else domainSelect"
              >
                {{ "u.domains_unselect_all" | transloco | capitalcase }}
              </span>
              <ng-template #domainSelect>
                <span class="whitespace-nowrap">
                  {{ "u.domains_select_all" | transloco | capitalcase }}
                </span>
              </ng-template>
            </div>
          </div>
        </ng-template>
        <ng-template
          pTemplate="body"
          let-rowNode
          let-rowData="rowData"
          let-columns="columns"
        >
          <tr>
            <td *ngFor="let col of columns; let i = index">
              <p-treeTableToggler
                [rowNode]="rowNode"
                *ngIf="i === 0"
              ></p-treeTableToggler>
              <p-treeTableCheckbox
                [value]="rowNode"
                *ngIf="i === 0"
              ></p-treeTableCheckbox>
              <span>
                {{ rowData[col.field] }}
              </span>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td [attr.colspan]="domainsCols.length">
              {{ "u.domain_no_found" | transloco | capitalcase }}
            </td>
          </tr>
        </ng-template>
      </p-treeTable>
    </div>
  </div>
  <div
    class="flex w-full pt-4"
    [ngClass]="{
      'justify-content-between': service.serviceName === 'UserAddService',
      'justify-content-end': service.serviceName === 'UserEditService',
    }"
  >
    <p-button
      label="{{ 'u.back' | transloco | capitalcase }}"
      (onClick)="prevStep()"
      icon="pi pi-angle-left"
      *ngIf="service.serviceName === 'UserAddService'"
    ></p-button>

    <p-button
      label="{{ 'u.next' | transloco | capitalcase }}"
      icon="pi pi-angle-right"
      iconPos="right"
      (onClick)="nextStep()"
      [disabled]="domainsControl.value.length === 0"
    ></p-button>
  </div>
</form>
<p-toast
  key="userForm"
  [breakpoints]="{ '577px': { width: '100%', right: '0', left: '0' } }"
  [showTransformOptions]="'translateY(100%)'"
  [showTransformOptions]="'translateX(100%)'"
></p-toast>

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { EnvConfig } from './app/environment-loader.service';

import * as Sentry from '@sentry/angular';

fetch('/assets/config/app-config.json')
  .then((res) => res.json())
  .then((config: EnvConfig) => {
    if (config.production) {
      enableProdMode();
    }

    if (config.production) {
      Sentry.init({
        dsn: config.sentryDsn,
        environment: config.sentryEnvironment,
        integrations: [
          Sentry.browserTracingIntegration(),
          Sentry.replayIntegration(),
        ],
        tracesSampleRate: config.tracesSampleRate || 0.2,
        tracePropagationTargets: [
          'api.yourserver.io',
          /https:\/\/.*\.yourserver\.io/,
        ],
        replaysSessionSampleRate: 0.05,
        replaysOnErrorSampleRate: 1.0,
      });
    }

    platformBrowserDynamic()
      .bootstrapModule(AppModule)
      .catch((err) =>
        console.error('Failed to bootstrap the Angular module:', err),
      );
  })
  .catch((err) => {
    console.error('Could not load config or bootstrap module', err);
  });

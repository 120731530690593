import {
  AbstractControl,
  AsyncValidatorFn,
  ValidationErrors,
} from '@angular/forms';
import { Observable, of } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
} from 'rxjs/operators';
import { UserAddDataService } from '../user-add/user-add-data.service';

export class CheckEmailValidator {
  static checkEmailForSubscription(
    userAddDataService: UserAddDataService,
  ): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!control.value) {
        return of(null);
      }
      return of(control.value).pipe(
        debounceTime(750),
        distinctUntilChanged(),
        switchMap(() => userAddDataService.checkUserAddEmail(control.value)),
        map((unique) => {
          if (unique.validateEmail === 1) {
            return { alreadyTemporary: true };
          } else if (unique.validateEmail === 2) {
            return { alreadyExist: true };
          }
          return null;
        }),
      );
    };
  }
}

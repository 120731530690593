import { Injectable } from '@angular/core';
import { AuthUser, UserProperty, UserFromBackend } from '../types';
import { AbstractService } from './abstract.service';

@Injectable({
  providedIn: 'root',
})
export class AuthUserService extends AbstractService<AuthUser> {
  override readonly labelInLocalStorage = 'authUser';
  override readonly propertyNamesInUserFromBackend: UserProperty[] = [
    'usersId',
    'firstName',
    'lastName',
  ];

  override getByUserFromBackend(userFromBackend: UserFromBackend): AuthUser {
    if (!userFromBackend.refreshToken || !userFromBackend.accessToken)
      throw new Error('No tokens provided');

    const { usersId, firstName, lastName } = userFromBackend;

    return {
      usersId,
      firstName,
      lastName,
    };
  }
}

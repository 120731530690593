import { inject, Injectable } from '@angular/core';
import { featurePermissions } from './feature-permissions.service';
import { WorkingDomainService } from '../components/auth/services';
import { Domain } from '../components/auth/types';
import { IFeaturePermission } from '../interfaces/IFeaturePermission';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  protected readonly workingDomainService: WorkingDomainService =
    inject(WorkingDomainService);

  hasPermission(permissionLabels: string[]): boolean {
    const userPermission: bigint[] | undefined = this.getPermissions();
    if (!userPermission) return false;
    const permissions: bigint[] = permissionLabels.map(
      (label: string) =>
        featurePermissions.find(
          (permission: IFeaturePermission) => permission.label === label,
        )?.permission ?? BigInt(0n),
    );
    return permissions.some((permissionBigInt: bigint) =>
      userPermission.some((permission) => permission === permissionBigInt),
    );
  }

  private getPermissions(): bigint[] | undefined {
    const workingDomain: Domain =
      this.workingDomainService.getDomainSubjectAsValue()!;
    const userPermissions: string = workingDomain?.permissions.slice(0, -1); // Delete suffix

    if (!userPermissions) return;
    const decodedUserPermissions: bigint[] = this.parsePermissions(
      BigInt(userPermissions),
    );
    return decodedUserPermissions;
  }

  private parsePermissions(serializedPermissions: bigint): bigint[] {
    const permissions: bigint[] = [];
    for (
      let permission: bigint = BigInt(1);
      permission <= serializedPermissions && permission > 0;
      permission <<= BigInt(1)
    ) {
      if (permission & serializedPermissions) permissions.push(permission);
    }
    return permissions;
  }
}

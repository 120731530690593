import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class EnvironmentLoaderService {
  private envConfig!: EnvConfig;
  private readonly http: HttpClient;

  constructor(private readonly httpBackend: HttpBackend) {
    this.http = new HttpClient(httpBackend);
  }

  async loadEnvConfig(configPath: string): Promise<void> {
    try {
      const rawEnvConfig: RawEnvConfig | EnvConfig = await lastValueFrom(
        this.http.get<RawEnvConfig | EnvConfig>(configPath),
      );
      this.envConfig = rawEnvConfig as EnvConfig;
    } catch (err) {
      console.log('Could not initialize app', err);
      process.exit(1);
    }
  }

  getEnvConfig(): EnvConfig {
    return this.envConfig;
  }
}

export interface RawEnvConfig {
  production: string;
}

export interface EnvConfig extends Omit<RawEnvConfig, 'production'> {
  production: boolean;
  sentryDsn: string;
  sentryEnvironment: string;
  tracesSampleRate: number;
  mapbox: { accessToken: string };
  backend: { baseUrl: string };
  luceGatewayBackend: { baseUrl: string };
  appVersion: string;
  legacyFrontend: { baseUrl: string };
}

import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthGuard } from './components/auth/guards/auth-guard.service';
import { UnauthGuard } from './components/auth/guards/unauth-guard.service';
import { LoginComponent } from './components/auth/login/login.component';
import { UserValidationComponent } from './components/users/user-validation/user-validation.component';
import { UnauthorizedComponent } from './shared/unauthorized/unauthorized.component';

const routes: Routes = [
  {
    path: 'account',
    children: [
      {
        path: 'create',
        children: [
          {
            path: ':token',
            component: UserValidationComponent,
          },
        ],
      },
    ],
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/login',
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [UnauthGuard],
  },
  {
    path: 'park',
    loadChildren: () =>
      import('./components/park/park.module').then((m) => m.ParkModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'import',
    loadChildren: () =>
      import('./components/import-csv/import-csv.module').then(
        (m) => m.ImportCsvModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'users',
    loadChildren: () =>
      import('./components/users/users.module').then((m) => m.UsersModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'lightpoint',
    loadChildren: () =>
      import('./components/lightpoint/lightpoint.module').then(
        (m) => m.LightpointModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'lucenode',
    loadChildren: () =>
      import('./components/lucenode/lucenode.module').then(
        (m) => m.LucenodeModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'assignment',
    loadChildren: () =>
      import('./components/lucenode/assignment/assignment.module').then(
        (m) => m.AssignmentModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'release',
    loadChildren: () =>
      import('./components/changelog/changelog.module').then(
        (m) => m.ChangelogModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HttpAbortService {
  private abortPendingRequests$ = new Subject<void>();

  abortPendingRequests() {
    this.abortPendingRequests$.next();
  }

  onAbortPendingRequests() {
    return this.abortPendingRequests$.asObservable();
  }
}

import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  LangDefinition,
  TranslocoModule,
  TranslocoService,
  getBrowserLang,
  provideTranslocoScope,
} from '@jsverse/transloco';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { MessagesModule } from 'primeng/messages';
import { PasswordModule } from 'primeng/password';
import { ToastModule } from 'primeng/toast';
import { Subscription, take } from 'rxjs';
import { ApplicationPipesModule } from 'src/app/app-pipes.module';
import { FirstLetterCapital } from 'src/app/services/first.capital.case.pipe';
import { CheckPasswordValidator } from 'src/app/validators/check-password.validator';
import { ILanguage, IUserEmail } from '../interfaces/users.interfaces';
import { UsersValidationDataService } from './user-validation.data.service';

const primengModules = [
  ButtonModule,
  CardModule,
  DividerModule,
  DropdownModule,
  InputTextModule,
  MessagesModule,
  PasswordModule,
  ToastModule,
];

@Component({
  selector: 'app-user-validation',
  templateUrl: './user-validation.component.html',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    ApplicationPipesModule,
    TranslocoModule,
    ...primengModules,
  ],
  providers: [
    provideTranslocoScope({ scope: 'users/registration', alias: 'ur' }),
    MessageService,
    FirstLetterCapital,
  ],
})
export class UserValidationComponent implements OnInit {
  private token!: string;
  email!: string;
  languages!: ILanguage[];
  availableLangs: LangDefinition[];
  browserLang!: string | undefined;
  private subscription: Subscription = Subscription.EMPTY;
  strongPasswordRegx: RegExp =
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)(?=.*?[^\w\s]).{15,}$/;
  userValidationForm: FormGroup = new FormGroup({
    firstname: new FormControl<string>('', [Validators.required]),
    lastname: new FormControl<string>('', [Validators.required]),
    password: new FormControl<string>('', {
      validators: [
        Validators.required,
        Validators.pattern(this.strongPasswordRegx),
      ],
    }),
    confirmPassword: new FormControl('', {
      validators: [Validators.required],
      asyncValidators: [
        CheckPasswordValidator.passwordCompare('password', 'confirmPassword'),
      ],
      updateOn: 'change',
    }),
    selectedLanguage: new FormControl('', Validators.required),
  });

  constructor(
    private readonly router: Router,
    private readonly messageService: MessageService,
    private readonly firstLetterCapital: FirstLetterCapital,
    private readonly activatedRoute: ActivatedRoute,
    private readonly usersValidationDataService: UsersValidationDataService,
    public translocoService: TranslocoService,
  ) {
    this.availableLangs =
      this.translocoService.getAvailableLangs() as LangDefinition[];
  }

  async ngOnInit(): Promise<void> {
    localStorage.clear();
    this.token = this.activatedRoute.snapshot.params['token'];
    this.browserLang = getBrowserLang() ?? 'en';
    this.languages = await this.retrieveFormatedLanguages();
    this.userValidationForm.patchValue({
      selectedLanguage: this.browserLang,
    });
    this.translocoService.setActiveLang(this.browserLang);
    this.userValidationForm
      .get('selectedLanguage')
      ?.valueChanges.subscribe((value) => {
        this.changeLanguage(value);
      });

    await this.getUserEmail(this.token);
  }

  changeLanguage(lang: string): void {
    this.subscription.unsubscribe();
    this.subscription = this.translocoService
      .load(lang)
      .pipe(take(1))
      .subscribe(() => {
        this.translocoService.setActiveLang(lang);
      });
  }

  async retrieveFormatedLanguages(): Promise<ILanguage[]> {
    const langs = await this.usersValidationDataService.getLanguages();
    return langs
      .map((lang) => {
        return {
          id: lang.label,
          label: lang.label,
          inactive: !['en', 'fr'].includes(lang.label),
        };
      })
      .filter((lang) => lang.inactive === false);
  }

  async getUserEmail(token: string): Promise<void> {
    const response: IUserEmail =
      await this.usersValidationDataService.getEmailFromToken(token);

    if (!response.email) {
      this.router.navigateByUrl('/login');
    } else {
      this.email = response.email;
    }
  }

  messageValidation(): void {
    this.messageService.add({
      key: 'validationForm',
      severity: 'success',
      summary: this.firstLetterCapital.transform(
        this.translocoService.translate('ur.toast.success'),
      ),
      detail: this.firstLetterCapital.transform(
        this.translocoService.translate('ur.toast.validate'),
      ),
    });
  }

  async register(): Promise<void> {
    return await this.usersValidationDataService.userValidation({
      token: this.token,
      firstname: this.userValidationForm.value.firstname,
      lastname: this.userValidationForm.value.lastname,
      password: this.userValidationForm.value.password,
      lang: this.userValidationForm.value.selectedLanguage,
    });
  }

  async submit(): Promise<void> {
    await this.register();
    this.messageValidation();
    setTimeout(() => {
      this.router.navigateByUrl('/login');
    }, 4000);
  }

  get firstname(): FormControl<string> {
    return this.userValidationForm.get('firstname') as FormControl;
  }

  get lastname(): FormControl<string> {
    return this.userValidationForm.get('lastname') as FormControl;
  }

  get password(): FormControl<string> {
    return this.userValidationForm.get('password') as FormControl;
  }

  get confirmPassword(): FormControl<string> {
    return this.userValidationForm.get('confirmPassword') as FormControl;
  }

  get selectedLanguage(): FormControl<string> {
    return this.userValidationForm.get('selectedLanguage') as FormControl;
  }

  get activeLang(): string {
    return this.translocoService.getActiveLang();
  }

  get activeLangLabel(): string | undefined {
    return this.availableLangs.find((lang) => lang.id === this.activeLang)
      ?.label;
  }
}

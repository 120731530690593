import {
  AbstractControl,
  AsyncValidatorFn,
  ValidationErrors,
} from '@angular/forms';
import { DateTime } from 'luxon';

export class CompareDateAndTodayValidator {
  static dateAndTodayCompare(): AsyncValidatorFn {
    return async (
      control: AbstractControl,
    ): Promise<ValidationErrors | null> => {
      const today = DateTime.now();
      const date = DateTime.fromJSDate(control.value);
      const result = today > date && date ? { invalidDate: true } : null;
      return result;
    };
  }
}

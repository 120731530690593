import { Component, inject } from '@angular/core';
import {
  UserFormComponent,
  userFormComponentParams,
} from '../abstracts/user-form.component';
import { DomainsSelectorComponent } from './steps/domains-selector/domains-selector.component';
import { EmailValidationComponent } from './steps/email-validation/email-validation.component';
import { OverviewComponent } from './steps/overview/overview.component';
import { PermissionsSelectorComponent } from './steps/permissions-selector/permissions-selector.component';
import { RolesSelectorComponent } from './steps/roles-selector/roles-selector.component';
import { UserExpirationComponent } from './steps/user-expiration/user-expiration.component';
import { UserAddService } from './user-add.service';

const { standalone, imports } = userFormComponentParams;

@Component({
  selector: 'app-user-add-form',
  templateUrl: './user-add-form.component.html',
  standalone,
  imports: [
    ...(imports as []),
    EmailValidationComponent,
    DomainsSelectorComponent,
    RolesSelectorComponent,
    PermissionsSelectorComponent,
    UserExpirationComponent,
    OverviewComponent,
  ],
})
export class UserAddFormComponent extends UserFormComponent<UserAddService> {
  readonly service = inject(UserAddService);
  protected steps: string[] = [
    'step_user_email',
    'step_domains',
    'step_role',
    'step_permission',
    'step_expiration',
    'step_overview',
  ];
}

import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HttpAbortService } from './http-abort.service';

@Injectable()
export class AbortHttpInterceptor implements HttpInterceptor {
  constructor(private httpAbortService: HttpAbortService) {}

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return next
      .handle(req)
      .pipe(takeUntil(this.httpAbortService.onAbortPendingRequests()));
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LocaleService {
  localeSubject: BehaviorSubject<string>;
  locale$: Observable<string>;

  constructor() {
    this.localeSubject = new BehaviorSubject<string>(
      localStorage.getItem('locale') ?? 'fr_FR',
    );
    this.locale$ = this.localeSubject.asObservable();
  }
  getLocale(): string {
    return this.localeSubject.value;
  }

  setLocale(locale: string): void {
    localStorage.setItem('locale', locale);
    this.localeSubject.next(locale);
  }
}

<p-toast></p-toast>
<div class="grid h-full">
  <div
    class="col-12 sm:col-12 md:col-12 lg:col-12 lg:flex align-items-center justify-content-center xl:col-8 xl:col-8 img-landscape"
  >
    <img
      alt="background login"
      src="../../../assets/images/background-blue-3.png"
      class="img-fluid"
    />
  </div>
  <div
    class="col-12 sm:col-12 md:col-12 lg:col-12 lg:flex align-items-center justify-content-center xl:col-4 form-landscape"
  >
    <p-card
      [header]="'a.welcome' | transloco | titlecase"
      [subheader]="'a.adventure' | transloco | capitalcase"
    >
      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" ngNativeValidate>
        <div class="field p-fluid">
          <span class="p-input-icon-right">
            <i class="pi pi-envelope"></i>
            <input
              [class]="
                email.invalid && (email.dirty || email.touched)
                  ? 'ng-invalid ng-dirty'
                  : ''
              "
              pInputText
              [placeholder]="'a.email' | transloco | capitalcase"
              formControlName="email"
              type="email"
              attr.aria-label="{{ 'a.email' | transloco }}"
              aria-describedby="email-help"
            />
          </span>
          <small id="email-help" class="p-error">
            <span *ngIf="email.invalid && (email.dirty || email.touched)">
              {{ "a.email" | transloco | capitalcase }}
              {{ "a.required" | transloco }}
            </span>
          </small>
        </div>
        <div class="field p-fluid">
          <p-password
            [feedback]="false"
            [placeholder]="'a.password' | transloco | capitalcase"
            formControlName="password"
            [class]="
              password.invalid && (password.dirty || password.touched)
                ? 'ng-invalid ng-dirty'
                : ''
            "
            ariaLabel="{{ 'a.password' | transloco }}"
            aria-describedby="password-help"
          >
          </p-password>
          <small id="password-help" class="p-error">
            <span
              *ngIf="password.invalid && (password.dirty || password.touched)"
            >
              {{ "a.password" | transloco | capitalcase }}
              {{ "a.required" | transloco }}
            </span>
          </small>
        </div>
        <p-button
          type="submit"
          [label]="'a.connection' | transloco | capitalcase"
          [disabled]="!loginForm.valid"
        ></p-button>
      </form>
    </p-card>
  </div>
</div>

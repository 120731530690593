<form [formGroup]="formGroup">
  <div class="flex flex justify-content-center w-full pt-5">
    <div class="flex flex-column gap-2">
      <label htmlFor="email">{{
        "u.email_placeholder" | transloco | capitalcase
      }}</label>
      <input
        pInputText
        id="email"
        class="w-20rem"
        aria-describedby="email-help"
        formControlName="emailControl"
      />
      <small
        id="email-help"
        class="p-error pl-1"
        *ngIf="emailControl.hasError('alreadyTemporary')"
      >
        {{ "u.wait_validation" | transloco | capitalcase }}
      </small>
      <small
        id="email-help"
        class="p-error pl-1"
        *ngIf="emailControl.hasError('alreadyExist')"
      >
        {{ "u.already_use" | transloco | capitalcase }}
      </small>
    </div>
  </div>
  <div class="flex justify-content-end">
    <p-button
      type="button"
      label="{{ 'u.next' | transloco | capitalcase }}"
      [disabled]="!emailControl.valid"
      (onClick)="nextStep()"
    ></p-button>
  </div>
</form>

import { Injectable } from '@angular/core';
import {
  ICheckEmail,
  ILucePendingUserInformation,
} from '../../interfaces/users.interfaces';
import { UserDataService } from '../abstracts/user-data.service';

type FormData = {
  lucePendingUserInformation: ILucePendingUserInformation;
};

@Injectable({
  providedIn: 'root',
})
export class UserAddDataService extends UserDataService<FormData, object> {
  checkUserAddEmail(email: string): Promise<ICheckEmail> {
    return this.httpService.fetchData<ICheckEmail>(
      `/users/email_checker`,
      this.translocoService.translate('u.error.check_email'),
      { params: { email: email } },
    );
  }

  submitForm({ lucePendingUserInformation }: FormData): Promise<object> {
    return this.httpService.postData(
      '/users/register_luce_pending_user',
      lucePendingUserInformation,
      this.translocoService.translate('u.error.add'),
    );
  }
}

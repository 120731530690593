import { ConcatLabels } from './services/concat.labels.pipe';
import { FirstLetterCapital } from './services/first.capital.case.pipe';
import { NgModule } from '@angular/core';
import { NoSanitizePipe } from './services/no.sanitize.pipe';
import { ShortNumberPipe } from './services/short-number.pipe';
import { DateFormatter } from './services/date.formatter.pipe';
import { ReleaseTagDecoderPipe } from './services/release-tag.decoder.pipe';

@NgModule({
  declarations: [
    FirstLetterCapital,
    ConcatLabels,
    ShortNumberPipe,
    NoSanitizePipe,
    DateFormatter,
    ReleaseTagDecoderPipe,
  ],
  exports: [
    FirstLetterCapital,
    ConcatLabels,
    ShortNumberPipe,
    NoSanitizePipe,
    DateFormatter,
    ReleaseTagDecoderPipe,
  ],
})
export class ApplicationPipesModule {}

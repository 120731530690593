import { CommonModule } from '@angular/common';
import { Component, Directive, inject, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { MessageService, TreeNode, TreeTableNode } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { ToastModule } from 'primeng/toast';
import {
  TreeTableHeaderCheckboxToggleEvent,
  TreeTableModule,
} from 'primeng/treetable';
import { ApplicationPipesModule } from 'src/app/app-pipes.module';
import { FirstLetterCapital } from 'src/app/services/first.capital.case.pipe';
import { IDomainsData } from '../../../../interfaces/users.interfaces';
import { ServiceUnion } from '../types';
import { UserFormStepComponent } from '../user-form-step.component';

export const domainsSelectorComponent: Component = {
  selector: 'app-domains-selector',
  templateUrl:
    '../../../abstracts/steps/domains-selector/domains-selector.component.html',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TreeTableModule,
    ApplicationPipesModule,
    TranslocoModule,
    ButtonModule,
    ToastModule,
    InputTextModule,
  ],
};

@Directive()
export abstract class AbstractDomainsSelectorComponent<Service>
  extends UserFormStepComponent<Service>
  implements OnInit
{
  private readonly firstLetterCapital = inject(FirstLetterCapital);
  private readonly messageService = inject(MessageService);
  protected abstract override service: Service;

  domainsList: TreeNode<IDomainsData>[] = [];
  selectedDomainTreeNode: TreeNode<IDomainsData>[] = [];
  domainsCols: { field: string }[] = [];
  selectedDomains:
    | TreeTableNode<IDomainsData>
    | TreeTableNode<IDomainsData>[]
    | null = [];
  isCheckedDomains: boolean = false;
  formGroup = new FormGroup({
    domainsControl: new FormControl([], [Validators.required]),
    domainUnique: new FormControl(false),
  });

  override async ngOnInit(): Promise<void> {
    super.ngOnInit();
    const service = this.service as ServiceUnion;

    this.domainsCols = [{ field: 'label' }];
    this.domainsList = await this.getUserDomainTreeNodes();
    this.domainUnique.setValue(false);

    if (
      this.domainsList.length === 1 &&
      !Array.isArray(this.domainsList[0].data?.childs)
    ) {
      this.domainUnique.setValue(true);
      this.domainsControl.setValue([this.domainsList[0].data!.id]);
      this.selectedDomains = [this.domainsList[0]];
      this.selectedDomainTreeNode = this.getSelectedItemsTree();
      this.fillForm();
      this.activeIndex++;
      this.activeIndexChange.emit(this.activeIndex);
    } else if (service.formService.getForm().selectedDomains.length > 0) {
      this.domainsControl.setValue(service.formService.getForm().domains);
      this.selectedDomains = service.formService.getForm()
        .selectedDomains as TreeTableNode<IDomainsData>[];
    } else if (this.config.data?.userInformationsToEdit.domains.length > 0) {
      const domainIds = this.config.data.userInformationsToEdit.domains.map(
        (domain: IDomainsData) => domain.id,
      );
      this.domainsControl.setValue(domainIds);
      this.domainConvertToSelectedTreeNode(
        this.config.data.userInformationsToEdit.domains,
      );
    } else {
      this.selectedDomains = [];
    }
  }

  async getAllUserDomains(): Promise<IDomainsData[]> {
    const service = this.service as ServiceUnion;
    return await service.sharedDataService.getUserDomains();
  }

  domainsConvertToTreeNode(domains: IDomainsData[]): TreeNode<IDomainsData>[] {
    const result: TreeNode[] = domains.map((domain: IDomainsData) => {
      return {
        label: domain.label,
        data: {
          id: domain.id,
          label: domain.label,
        },
        children: domain.childs
          ? this.domainsConvertToTreeNode(domain.childs)
          : [],
      };
    });
    return result;
  }

  async getUserDomainTreeNodes(): Promise<TreeNode<IDomainsData>[]> {
    const allUserDomains: IDomainsData[] = await this.getAllUserDomains();
    return this.domainsConvertToTreeNode(allUserDomains);
  }

  domainConvertToSelectedTreeNode(domains: IDomainsData[]): void {
    const domainsMap = new Map<number, TreeNode<IDomainsData>>(
      domains.map((domain) => [
        domain.id,
        {
          label: domain.label,
          data: { id: domain.id, label: domain.label },
          children: [],
        },
      ]),
    );

    const result: TreeNode<IDomainsData>[] = [];

    domains.forEach((domain) => {
      const node = domainsMap.get(domain.id);
      if (node && domain.domainIdParent !== null) {
        const parentNode = domainsMap.get(domain.domainIdParent!);
        if (parentNode) {
          node.parent = parentNode;
          parentNode.children?.push(node);
        }
      }
      result.push(node!);
    });

    result.forEach((node: TreeNode, index: number) => {
      if (node.parent === undefined) {
        result.splice(index, 1);
        result.unshift(node);
      }
    });
    this.selectedDomains = result;
  }

  domainToggleSelection(): void {
    const selectDomainIds: number[] =
      (this.selectedDomains &&
        (this.selectedDomains as TreeNode<IDomainsData>[]).map(
          (domain: TreeNode<IDomainsData>) => domain.data!.id,
        )) ??
      [];
    this.domainsControl.setValue(selectDomainIds);
    this.selectedDomainTreeNode = this.getSelectedItemsTree();
  }

  messageServiceDomainToggleSelection(
    isSelected: boolean,
    domainLabel: string,
  ): void {
    const message = isSelected
      ? this.firstLetterCapital.transform(
          this.translocoService.translate('u.messages.selected'),
        )
      : this.firstLetterCapital.transform(
          this.translocoService.translate('u.messages.unselected'),
        );

    const severity = isSelected ? 'success' : 'info';

    this.toastMessageService(
      'userForm',
      severity,
      this.firstLetterCapital.transform(message),
      domainLabel,
    );
  }

  domainSelectionChange(
    event: TreeTableNode<IDomainsData>,
    isSelected: boolean,
  ): void {
    this.domainToggleSelection();
    this.messageServiceDomainToggleSelection(
      isSelected,
      event.node!.data!.label,
    );
  }

  onHeaderCheckboxToggleAllDomains(event: TreeTableHeaderCheckboxToggleEvent) {
    if (event.checked) {
      this.isCheckedDomains = true;
      this.domainToggleSelection();
    } else {
      this.isCheckedDomains = false;
      this.domainsControl.setValue([]);
    }
  }

  private getSelectedItemsTree(): TreeNode<IDomainsData>[] {
    const selectedItemsTree: TreeNode<IDomainsData>[] = [];
    const nodeMap = new Map<string, TreeNode<IDomainsData>>();

    const cleanedSelectedDomains: number[] = (
      this.selectedDomains as number[]
    ).filter(
      (
        item: IDomainsData | number,
        _index: number,
        self: (IDomainsData | number)[],
      ) =>
        !self.some(
          (otherItem: IDomainsData | number) =>
            (otherItem as IDomainsData).label !==
              (item as IDomainsData).label &&
            isDescendant(item as IDomainsData, otherItem as IDomainsData),
        ),
    );

    cleanedSelectedDomains.forEach((item: IDomainsData | number) => {
      addNodeToTree(item as IDomainsData);
    });

    return selectedItemsTree;
    function addNodeToTree(
      node: TreeNode<IDomainsData>,
      parentNode?: TreeNode<IDomainsData>,
    ) {
      let treeNode = node.label && nodeMap.get(node.label);

      if (!treeNode) {
        treeNode = {
          label: node.label,
          data: node.data,
          children: [],
        };
        node.label && nodeMap.set(node.label, treeNode);
      }

      if (parentNode) {
        if (
          !parentNode.children?.some(
            (child: TreeNode<IDomainsData>) => child.label === node.label,
          )
        ) {
          parentNode.children?.push(treeNode);
        }
      } else {
        if (
          !selectedItemsTree.some(
            (item: TreeNode<IDomainsData>) => item.label === node.label,
          )
        ) {
          selectedItemsTree.push(treeNode);
        }
      }

      if (node.children) {
        node.children.forEach((childNode: TreeNode<IDomainsData>) => {
          addNodeToTree(childNode, (treeNode as TreeNode<IDomainsData>)!);
        });
      }
    }

    function isDescendant(
      descendant: TreeNode<IDomainsData>,
      ancestor: TreeNode<IDomainsData>,
    ): boolean {
      if (!ancestor.children) {
        return false;
      }
      for (const child of ancestor.children) {
        if (
          child.label === descendant.label ||
          isDescendant(descendant, child)
        ) {
          return true;
        }
      }
      return false;
    }
  }

  protected fillForm(): void {
    const service = this.service as ServiceUnion;
    service.formService.setForm({
      ...service.formService.getForm(),
      domains: this.domainsControl.value,
      selectedDomains: this.selectedDomains,
      selectedDomainTree: this.selectedDomainTreeNode,
      domainUnique: this.domainUnique.value,
    } as ServiceUnion['formService']['form']);
  }

  private toastMessageService(
    key: string,
    severity: string,
    summary: string,
    detail?: string,
  ): void {
    this.messageService.add({
      key: key,
      severity: severity,
      summary: summary,
      detail: detail,
    });
  }

  get domainsControl(): FormControl<number[]> {
    return this.formGroup.get('domainsControl') as FormControl;
  }

  get domainUnique(): FormControl<boolean> {
    return this.formGroup.get('domainUnique') as FormControl;
  }
}

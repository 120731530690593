import { Injectable } from '@angular/core';
import { UserEditFormService } from './user-edit-form.service';
import { UserService } from '../abstracts/user.service';
import { UserEditDataService } from './user-edit-data.service';

@Injectable({
  providedIn: 'root',
})
export class UserEditService extends UserService<
  UserEditFormService,
  UserEditDataService
> {
  readonly serviceName: string = 'UserEditService';
  constructor(
    formService: UserEditFormService,
    dataService: UserEditDataService,
  ) {
    super(formService, dataService);
  }
}

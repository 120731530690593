import { Component, inject } from '@angular/core';
import {
  AbstractPermissionsSelectorComponent,
  permissionsSelectorComponentParams,
} from '../../../abstracts/steps/permissions-selector/permissions-selector.component';
import { UserEditService } from '../../user-edit.service';

const { selector, standalone, imports } = permissionsSelectorComponentParams;

@Component({
  selector: selector + '[data-service="UserEditService"]',
  templateUrl:
    '../../../abstracts/steps/permissions-selector/permissions-selector.component.html',
  standalone,
  imports,
})
export class PermissionsSelectorComponent extends AbstractPermissionsSelectorComponent<UserEditService> {
  protected service = inject(UserEditService);
}

<div class="flex p-2 justify-content-between flex-column h-full">
  <header class="flex align-items-center justify-content-between">
    <div *ngIf="!isCollapsed()" class="flex align-items-center gap-2">
      <h1 data-testid="menu:title">Luce</h1>
      <span class="text-sm text-gray-400">{{ version }}</span>
    </div>
    <p-button
      class="flex w-full justify-content-end"
      [text]="true"
      icon="{{ isCollapsed() ? 'pi pi-angle-right' : 'pi pi-angle-left' }}"
      (onClick)="toggleMenu()"
    />
  </header>
  <nav class="h-full" data-testid="menu:nav">
    <ul class="list-none p-0">
      <li class="pb-2" *ngFor="let navLink of navLinks">
        <p-button
          styleClass="w-full"
          *ngIf="navLink.visible"
          icon="pi pi-{{ navLink.icon }}"
          [text]="!isRouteActive(navLink.routerLink)"
          [label]="
            !isCollapsed()
              ? ('m.' + navLink.label | transloco | capitalcase)
              : ''
          "
          (onClick)="navigateTo(navLink.routerLink)"
        />
      </li>
    </ul>
  </nav>
  <footer>
    <p-overlayPanel #op>
      <ng-template pTemplate="content">
        <div>
          <div class="flex font-semibold text-base">
            {{ "m.support_content.title" | transloco | capitalcase }}
          </div>
          <span class="flex gap-2 mt-2"
            ><i class="pi pi-phone"></i>
            <a href="tel:+330456005000">04.56.00.50.00</a>
          </span>
        </div>
      </ng-template>
    </p-overlayPanel>
    <p-button
      styleClass="w-full mb-2"
      severity="secondary"
      (onClick)="op.toggle($event)"
      icon="fa-solid fa-headset"
      [label]="
        !isCollapsed()
          ? ('m.support_content.title' | transloco | capitalcase)
          : ''
      "
    />
    <p-button
      styleClass="w-full"
      data-testid="menu:logout"
      severity="danger"
      (onClick)="logOut()"
      icon="pi pi-sign-out"
      [label]="!isCollapsed() ? ('m.logout' | transloco | capitalcase) : ''"
    />
  </footer>
</div>

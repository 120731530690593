import {
  Directive,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { ServiceUnion } from './types';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Directive()
export abstract class UserFormStepComponent<Service> implements OnInit {
  protected readonly ref = inject(DynamicDialogRef);
  protected config: DynamicDialogConfig = inject(DynamicDialogConfig);
  readonly translocoService = inject(TranslocoService);
  protected abstract service: Service;

  @Input({
    required: true,
  })
  activeIndex!: number;
  @Output() activeIndexChange: EventEmitter<number> = new EventEmitter();

  ngOnInit(): void {
    const service = this.service as ServiceUnion;
    this.ref.onClose.subscribe(() => {
      service.formService.resetForm();
    });
  }

  nextStep() {
    this.fillForm();
    this.activeIndex++;
    this.activeIndexChange.emit(this.activeIndex);
  }

  prevStep() {
    if (this.activeIndex === 0) return;
    this.activeIndex--;
    this.activeIndexChange.emit(this.activeIndex);
  }

  protected abstract fillForm(): void;
}

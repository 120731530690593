import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { EnvironmentLoaderService } from 'src/app/environment-loader.service';
import { IChangeLogItem } from './change.interface';

@Injectable({
  providedIn: 'root',
})
export class ChangelogDataService {
  private readonly baseUrl: string;

  constructor(
    private readonly http: HttpClient,
    private readonly envService: EnvironmentLoaderService,
  ) {
    this.baseUrl = this.envService.getEnvConfig().backend.baseUrl;
  }

  getChangelog(): Promise<IChangeLogItem[]> {
    return lastValueFrom(
      this.http.get<IChangeLogItem[]>(`${this.baseUrl}/changelog/all`),
    );
  }

  getLatestRelease(): Promise<IChangeLogItem> {
    return lastValueFrom(
      this.http.get<IChangeLogItem>(`${this.baseUrl}/changelog/latest_release`),
    );
  }
}

<p-toast key="addUser"></p-toast>
<p-steps
  [model]="translatedSteps"
  [(activeIndex)]="activeIndex"
  (activeIndexChange)="onActiveIndexChange($event)"
  [readonly]="true"
  styleClass="min-w-min"
>
</p-steps>
<div class="steps-content">
  <ng-container [ngSwitch]="activeIndex">
    <app-email-validation
      [activeIndex]="activeIndex"
      (activeIndexChange)="getActiveIndexChange($event)"
      *ngSwitchCase="0"
    />
    <app-domains-selector
      data-service="UserAddService"
      [activeIndex]="activeIndex"
      (activeIndexChange)="getActiveIndexChange($event)"
      *ngSwitchCase="1"
    />
    <app-roles-selector
      data-service="UserAddService"
      [activeIndex]="activeIndex"
      (activeIndexChange)="getActiveIndexChange($event)"
      *ngSwitchCase="2"
    />
    <app-permissions-selector
      data-service="UserAddService"
      [activeIndex]="activeIndex"
      (activeIndexChange)="getActiveIndexChange($event)"
      *ngSwitchCase="3"
    />
    <app-user-expiration
      data-service="UserAddService"
      [activeIndex]="activeIndex"
      (activeIndexChange)="getActiveIndexChange($event)"
      *ngSwitchCase="4"
    />
    <app-overview
      data-service="UserAddService"
      [activeIndex]="activeIndex"
      (activeIndexChange)="getActiveIndexChange($event)"
      *ngSwitchCase="5"
    />
  </ng-container>
</div>

import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'releaseTagDecoder' })
@Injectable({ providedIn: 'root' })
export class ReleaseTagDecoderPipe implements PipeTransform {
  transform(value: number): string {
    switch (value) {
      case 1:
        return 'new';
      case 2:
        return 'improved';
      case 3:
        return 'fixed';
      default:
        return '';
    }
  }
}

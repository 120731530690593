import { inject, Injectable } from '@angular/core';
import { UserSharedDataService } from '../user-shared-data.service';

@Injectable({
  providedIn: 'root',
})
export abstract class UserService<FormService, DataService> {
  protected abstract readonly serviceName: string;
  readonly sharedDataService: UserSharedDataService = inject(
    UserSharedDataService,
  );

  protected constructor(
    readonly formService: FormService,
    readonly dataService: DataService,
  ) {
    this.formService = formService;
    this.dataService = dataService;
  }
}

import { inject, Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { HttpService } from 'src/app/services/http.service';

@Injectable({
  providedIn: 'root',
})
export abstract class UserDataService<FormData, ResponseData> {
  protected readonly translocoService = inject(TranslocoService);
  protected readonly httpService: HttpService = inject(HttpService);

  abstract submitForm(...args: FormData[]): Promise<ResponseData>;
}

import { Injectable } from '@angular/core';
import packageJson from '../../package.json';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  public readonly appVersion: string = packageJson.version;

  constructor() {}
}

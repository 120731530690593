import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AuthGuard } from './guards/auth-guard.service';
import { UnauthGuard } from './guards/unauth-guard.service';
import { AuthService } from './auth.service';
import { ApplicationPipesModule } from 'src/app/app-pipes.module';
import { TranslocoModule, provideTranslocoScope } from '@jsverse/transloco';
import { JwtModule } from '@auth0/angular-jwt';
import { ButtonModule } from 'primeng/button';
import { PasswordModule } from 'primeng/password';
import { InputTextModule } from 'primeng/inputtext';
import { CardModule } from 'primeng/card';
import { MessageModule } from 'primeng/message';
import { DialogService } from 'primeng/dynamicdialog';
import { ToastModule } from 'primeng/toast';

export function tokenGetter() {
  return localStorage.getItem('accessToken');
}

@NgModule({
  declarations: [LoginComponent],
  providers: [
    AuthGuard,
    AuthService,
    DialogService,
    UnauthGuard,
    provideTranslocoScope({ scope: 'auth', alias: 'a' }),
  ],
  imports: [
    CommonModule,
    RouterModule,
    TranslocoModule,
    HttpClientModule,
    ReactiveFormsModule,
    ApplicationPipesModule,
    MessageModule,
    InputTextModule,
    ButtonModule,
    PasswordModule,
    CardModule,
    ToastModule,
    JwtModule.forRoot({ config: { tokenGetter: tokenGetter } }),
  ],
})
export class AuthModule {}

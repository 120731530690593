import { Injectable } from '@angular/core';
import { UserAddFormService } from './user-add-form.service';
import { UserService } from '../abstracts/user.service';
import { UserAddDataService } from './user-add-data.service';

@Injectable({
  providedIn: 'root',
})
export class UserAddService extends UserService<
  UserAddFormService,
  UserAddDataService
> {
  readonly serviceName: string = 'UserAddService';
  constructor(
    formService: UserAddFormService,
    dataService: UserAddDataService,
  ) {
    super(formService, dataService);
  }
}

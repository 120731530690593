import {
  ChangeDetectorRef,
  Component,
  OnInit,
  signal,
  ViewEncapsulation,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { provideTranslocoScope, TranslocoService } from '@jsverse/transloco';
import { MessageService } from 'primeng/api';
import { VersionParts } from 'src/assets/app.interface';
import { AppService } from './app.service';
import { AuthService } from './components/auth/auth.service';
import {
  Features,
  IChangeLogItem,
} from './components/changelog/change.interface';
import { ChangelogDataService } from './components/changelog/changelog.data.service';
import { LocaleService } from './services/locale.service';
import { WorkingDomainService } from './components/auth/services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [
    MessageService,
    provideTranslocoScope({ scope: 'app', alias: 'app' }),
  ],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
  private title = 'LUCENODE';
  isLoggedIn: boolean = false;
  currentLang!: string;
  visible: boolean = false;
  currentVersion!: string;
  latestRelease!: IChangeLogItem;

  readonly isMenuCollapsed = signal<boolean>(true);

  constructor(
    private readonly titleService: Title,
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly appService: AppService,
    private readonly translocoService: TranslocoService,
    private readonly workingDomainService: WorkingDomainService,
    private cdr: ChangeDetectorRef,
    private localeService: LocaleService,
    private messageService: MessageService,
    private readonly changelogDataService: ChangelogDataService,
  ) {
    this.titleService.setTitle(this.title);
    setInterval(() => this.checkAndUpdateVersion(), 1800000);
  }

  async ngOnInit(): Promise<void> {
    this.currentLang = this.translocoService.getActiveLang();

    this.currentVersion = this.appService.appVersion;
    this.localeService.locale$.subscribe(() => {
      // Trigger change detection
      this.cdr.detectChanges();
    });

    const browserLang: string = this.translocoService.getDefaultLang();
    if (!['en', 'fr'].includes(browserLang)) {
      this.translocoService.setActiveLang('en');
    }
    this.authService.isLoggedIn.subscribe(async (isLoggedIn: boolean) => {
      this.isLoggedIn = isLoggedIn;

      // Add a data-testid="logged-in:{{ isLoggedIn }}" to the <app-root> element
      const dataTestid = document.createAttribute('data-testid');
      dataTestid.value = `logged-in:${isLoggedIn}`;
      document.querySelector('app-root')?.setAttributeNode(dataTestid);

      if (this.isLoggedIn) {
        this.latestRelease = await this.changelogDataService.getLatestRelease();
      }
    });
  }

  async getAppVersion(current: string, latest: string): Promise<void> {
    const needToUpdate: boolean = this.compareVersions(current, latest);

    if (needToUpdate) {
      this.newVersionMessage(latest);
    }
  }

  async newVersionMessage(latestVersion: string): Promise<void> {
    return this.messageService.add({
      key: 'newVersion',
      severity: 'info',
      summary: 'app.release',
      detail: `Version ${latestVersion}`,
      life: 86400000,
      sticky: true,
      closable: false,
    });
  }

  compareVersions(currentVersion: string, latestVersion: string): boolean {
    const current: VersionParts = this.parseVersion(currentVersion);
    const latest: VersionParts = this.parseVersion(latestVersion);

    if (latest.major !== current.major) {
      return latest.major > current.major;
    }

    if (latest.minor !== current.minor) {
      return latest.minor > current.minor;
    }

    return latest.patch > current.patch;
  }

  parseVersion(version: string): VersionParts {
    if (!RegExp(/^(\d+)\.(\d+)\.(\d+)$/).exec(version)) {
      throw new Error('Invalid version format');
    }
    const [major, minor, patch]: number[] = version.split('.').map(Number);

    return {
      major,
      minor,
      patch,
    };
  }

  refreshCacheAndReloadPage(): void {
    if ('caches' in window) {
      caches.keys().then((names: string[]) => {
        names.forEach(async (name: string) => {
          await caches.delete(name);
        });
      });
      window.location.reload();
    }
  }

  showDialog(): void {
    this.visible = !this.visible;
  }

  checkAndUpdateVersion(): void {
    if (this.isLoggedIn && this.latestRelease) {
      this.getAppVersion(this.currentVersion, this.latestRelease.version);
    }
  }

  filterFeaturesByLang(features: Features[]): Features[] {
    return features.filter(
      (feature: Features) => feature.lang === this.currentLang,
    );
  }

  onMenuCollapse(event: boolean): void {
    this.isMenuCollapsed.set(event);
  }
}

import { CommonModule } from '@angular/common';
import { Component, inject, Input, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { Subscription } from 'rxjs';
import { ApplicationPipesModule } from 'src/app/app-pipes.module';
import { CheckEmailValidator } from 'src/app/components/users/user-forms/validators/check-email.validator';
import { UserFormStepComponent } from '../../../abstracts/steps/user-form-step.component';
import { UserAddService } from '../../user-add.service';

@Component({
  selector: 'app-email-validation',
  templateUrl: './email-validation.component.html',
  standalone: true,
  imports: [
    ApplicationPipesModule,
    TranslocoModule,
    CommonModule,
    ReactiveFormsModule,
    ButtonModule,
    InputTextModule,
  ],
})
export class EmailValidationComponent
  extends UserFormStepComponent<UserAddService>
  implements OnInit
{
  @Input()
  override service: UserAddService = inject(UserAddService);
  subscription!: Subscription;
  formGroup = new FormGroup({
    emailControl: new FormControl('', {
      validators: [
        Validators.required,
        Validators.email,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
      ],
      asyncValidators: [
        CheckEmailValidator.checkEmailForSubscription(this.service.dataService),
      ],
      updateOn: 'change',
    }),
  });

  override ngOnInit(): void {
    super.ngOnInit();
    this.emailControl.setValue(this.getEmailFromUserFormService());
  }

  getEmailFromUserFormService(): string {
    return this.service.formService.getForm().email ?? '';
  }

  protected fillForm(): void {
    this.service.formService.setForm({
      ...this.service.formService.getForm(),
      email: this.emailControl.value,
    });
  }

  get emailControl(): FormControl<string> {
    return this.formGroup.get('emailControl') as FormControl;
  }
}

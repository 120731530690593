import { CommonModule } from '@angular/common';
import { Component, Directive, inject, OnInit } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { MessageService, TreeNode } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ToastModule } from 'primeng/toast';
import { TreeModule } from 'primeng/tree';
import { ApplicationPipesModule } from 'src/app/app-pipes.module';
import { IFeaturePermission } from 'src/app/interfaces/IFeaturePermission';
import { featurePermissions } from 'src/app/services/feature-permissions.service';
import { ServiceUnion } from '../types';
import { UserFormStepComponent } from '../user-form-step.component';

export const overviewComponentParams: Component = {
  selector: 'app-overview',
  templateUrl: '../../../abstracts/steps/overview/overview.component.html',
  standalone: true,
  imports: [
    CommonModule,
    TranslocoModule,
    ApplicationPipesModule,
    TreeModule,
    ButtonModule,
    ToastModule,
  ],
};

@Directive()
export abstract class AbstractOverviewComponent<Service>
  extends UserFormStepComponent<Service>
  implements OnInit
{
  protected readonly messageService = inject(MessageService);
  protected abstract override service: Service;

  email: string = '';
  domains: number[] = [];
  role: string = '';
  permissions: string = '';
  expirationDate: string | null = null;
  domainsResumeList: TreeNode[] = [];
  permissionsSummaryTreeNodes: TreeNode[] = [];

  override ngOnInit(): void {
    super.ngOnInit();
    this.initValues();
  }

  fillForm(): void {}

  protected abstract submit(): Promise<void>;

  private initValues(): void {
    const service = this.service as ServiceUnion;

    const {
      email,
      domains,
      role,
      permissions,
      expirationDate,
      selectedDomainTree,
    } = service.formService.getForm();
    this.email = email;
    this.domains = domains;
    this.role = role;
    this.permissions = permissions;
    this.permissionsSummaryTreeNodes = this.getPermissionsSummaryTreeNodes();
    this.expirationDate = expirationDate ?? null;
    this.domainsResumeList = selectedDomainTree;
  }

  private absoluteValueBigInt(value: bigint): bigint {
    return value < 0 ? -value : value;
  }

  private parsePermissions(serializedPermissions: bigint): bigint[] {
    const permissions: bigint[] = [];
    for (
      let permission: bigint = BigInt(1);
      permission <= serializedPermissions && permission > 0;
      permission <<= BigInt(1)
    ) {
      if (permission & serializedPermissions) permissions.push(permission);
    }
    return permissions;
  }

  private getPermissionsSummaryTreeNodes(): TreeNode[] {
    const permissionValue: bigint = this.absoluteValueBigInt(
      BigInt(this.permissions),
    );
    const arrayPermission: bigint[] = this.parsePermissions(permissionValue);

    const filteredFeatures: IFeaturePermission[] = featurePermissions.filter(
      (feature) => {
        return arrayPermission.includes(feature.permission);
      },
    );

    const categoryMap: Map<string, TreeNode> = new Map();
    const result: TreeNode[] = [];

    for (const feature of filteredFeatures) {
      if (!categoryMap.has(feature.parentCategory)) {
        categoryMap.set(feature.parentCategory, {
          data: {
            label: feature.parentCategory,
          },
          children: [],
        });
      }

      const category = categoryMap.get(feature.parentCategory);
      if (category) {
        category.children!.push({
          data: {
            label: feature.label,
            permmission: BigInt(feature.permission),
          },
        });
      }
    }

    categoryMap.forEach((value) => {
      result.push(value);
    });

    return result;
  }
}

import { Component, inject } from '@angular/core';
import {
  AbstractDomainsSelectorComponent,
  domainsSelectorComponent,
} from '../../../abstracts/steps/domains-selector/domains-selector.component';
import { UserEditService } from '../../user-edit.service';

const { selector, templateUrl, standalone, imports } = domainsSelectorComponent;

@Component({
  selector: selector + '[data-service="UserEditService"]',
  templateUrl,
  standalone,
  imports,
})
export class DomainsSelectorComponent extends AbstractDomainsSelectorComponent<UserEditService> {
  protected service = inject(UserEditService);
}

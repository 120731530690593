import { Component, OnInit } from '@angular/core';
import { provideTranslocoScope, TranslocoService } from '@jsverse/transloco';
import { Changes, Features, IChangeLogItem } from './change.interface';
import { ChangelogDataService } from './changelog.data.service';

@Component({
  selector: 'app-changelog',
  templateUrl: './changelog.component.html',
  styleUrls: ['./changelog.component.scss'],
  providers: [provideTranslocoScope({ scope: 'changelog', alias: 'cl' })],
})
export class ChangelogComponent implements OnInit {
  currentLang!: string;
  rawChangelog!: IChangeLogItem[];
  changelog!: IChangeLogItem[];

  constructor(
    public translocoService: TranslocoService,
    private readonly ChangelogDataService: ChangelogDataService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.currentLang = this.translocoService.getActiveLang();
    this.rawChangelog = await this.ChangelogDataService.getChangelog();
    this.changelog = this.changelogFormatter(
      this.rawChangelog,
      this.currentLang,
    );
  }

  changelogFormatter(
    rawChangelog: IChangeLogItem[],
    currentLang: string,
  ): IChangeLogItem[] {
    return rawChangelog
      .map((raw: IChangeLogItem) => ({
        ...raw,
        changes: raw.changes
          ?.map((change: Changes) => ({
            ...change,
            message: change.features.filter(
              (feature: Features) => feature.lang === currentLang,
            ),
          }))
          .sort((a, b) => a.tag - b.tag),
      }))
      .sort(
        (a, b) =>
          new Date(b.releaseDate).getTime() - new Date(a.releaseDate).getTime(),
      );
  }
}

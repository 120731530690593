import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';

import { Injectable } from '@angular/core';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root',
})
export class UnauthGuard implements CanActivate {
  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    return !this.authService.validateAccess(route, (isLoggedIn) =>
      this.handleLoginStatus(isLoggedIn),
    );
  }

  private handleLoginStatus(isLoggedIn: boolean): void {
    if (!isLoggedIn) return;
    this.router.navigate(['/park']);
  }
}

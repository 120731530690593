import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeEn from '@angular/common/locales/en';
import localeFr from '@angular/common/locales/fr';
import {
  APP_INITIALIZER,
  ErrorHandler,
  LOCALE_ID,
  NgModule,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { provideTranslocoScope } from '@jsverse/transloco';
import * as Sentry from '@sentry/angular';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { SidebarModule } from 'primeng/sidebar';
import { TagModule } from 'primeng/tag';
import { ToastModule } from 'primeng/toast';
import { ApplicationPipesModule } from './app-pipes.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './components/auth/auth.module';
import { AuthInterceptor } from './components/auth/interceptors/auth.interceptor';
import { ChangelogModule } from './components/changelog/changelog.module';
import { MenuComponent } from './components/menu/menu.component';
import { UserValidationComponent } from './components/users/user-validation/user-validation.component';
import { UsersModule } from './components/users/users.module';
import { EnvironmentLoaderService } from './environment-loader.service';
import { FirstLetterCapital } from './services/first.capital.case.pipe';
import { LocaleService } from './services/locale.service';
import { BreadcrumbComponent } from './shared/breadcrumb/breadcrumb.component';
import { AbortHttpInterceptor } from './shared/http-abort/http-abort.interceptor';
import { HttpErrorInterceptor } from './shared/http-error-handler/http-error.interceptor';
import { TranslocoRootModule } from './transloco-root.module';

const primeNG = [
  ButtonModule,
  DialogModule,
  SidebarModule,
  TagModule,
  ToastModule,
];

registerLocaleData(localeFr);
registerLocaleData(localeEn);

const initAppFn = (envService: EnvironmentLoaderService) => {
  return () => envService.loadEnvConfig('/assets/config/app-config.json');
};

export function localeFactory(localeService: LocaleService) {
  return localeService.getLocale();
}

@NgModule({
  bootstrap: [AppComponent],
  declarations: [AppComponent],
  imports: [
    ApplicationPipesModule,
    MenuComponent,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    TranslocoRootModule,
    ApplicationPipesModule,
    AuthModule,
    UsersModule,
    BreadcrumbComponent,
    UserValidationComponent,
    BreadcrumbComponent,
    ChangelogModule,
    ...primeNG,
  ],
  providers: [
    provideTranslocoScope(
      {
        scope: 'menu',
        alias: 'm',
      },
      {
        scope: 'auth',
        alias: 'a',
      },
      {
        scope: 'http-error',
        alias: 'he',
      },
    ),
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: HttpErrorInterceptor,
    },
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: AuthInterceptor,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AbortHttpInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initAppFn,
      multi: true,
      deps: [EnvironmentLoaderService],
    },
    MessageService,
    FirstLetterCapital,
    {
      provide: LOCALE_ID,
      useFactory: localeFactory,
      deps: [LocaleService],
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
})
export class AppModule {
  constructor(trace: Sentry.TraceService) {}
}

<form [formGroup]="useraddFormGroup">
  <div class="flex flex justify-content-center w-full pt-8">
    <span class="p-float-label">
      <p-calendar
        inputStyleClass="w-20rem"
        formControlName="expirationDateControl"
        [minDate]="today"
        [touchUI]="true"
        dateFormat="dd/mm/yy"
        appendTo="body"
      ></p-calendar>
      <label for="expiration" class="white-space-nowrap">
        {{ "u.expiration_placeholder" | transloco | capitalcase }}
      </label>
    </span>
  </div>
  <div class="flex justify-content-between w-full pt-4">
    <p-button
      label="{{ 'u.back' | transloco | capitalcase }}"
      (onClick)="prevStep()"
      icon="pi pi-angle-left"
    ></p-button>
    <p-button
      label="{{ 'u.next' | transloco | capitalcase }}"
      icon="pi pi-angle-right"
      iconPos="right"
      (onClick)="nextStep()"
      [disabled]="expirationDateControl.invalid"
    ></p-button>
  </div>
</form>

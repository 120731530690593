import { Injectable } from '@angular/core';
import { UserFormService } from '../abstracts/user-form.service';
import { IUserAddForm } from './user-add.interface';

@Injectable({
  providedIn: 'root',
})
export class UserAddFormService extends UserFormService<IUserAddForm> {
  readonly defaultForm: IUserAddForm = {
    email: '',
    domains: [],
    selectedDomains: [],
    selectedDomainTree: [],
    domainUnique: false,
    role: '',
    permissions: '',
  };

  form: IUserAddForm = this.defaultForm;
}

import { Component, inject, OnInit } from '@angular/core';
import {
  UserFormComponent,
  userFormComponentParams,
} from '../abstracts/user-form.component';
import { EmailValidationComponent } from '../user-add/steps/email-validation/email-validation.component';
import { DomainsSelectorComponent } from './steps/domains-selector/domains-selector.component';
import { OverviewComponent } from './steps/overview/overview.component';
import { PermissionsSelectorComponent } from './steps/permissions-selector/permissions-selector.component';
import { RolesSelectorComponent } from './steps/roles-selector/roles-selector.component';
import { UserExpirationComponent } from './steps/user-expiration/user-expiration.component';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { UserEditDataService } from './user-edit-data.service';

const { standalone, imports } = userFormComponentParams;

@Component({
  selector: 'app-user-edit-form',
  templateUrl: './user-edit-form.component.html',
  standalone,
  imports: [
    ...(imports as []),
    EmailValidationComponent,
    DomainsSelectorComponent,
    RolesSelectorComponent,
    PermissionsSelectorComponent,
    UserExpirationComponent,
    OverviewComponent,
  ],
})
export class UserEditFormComponent
  extends UserFormComponent<UserEditDataService>
  implements OnInit
{
  private readonly config: DynamicDialogConfig = inject(DynamicDialogConfig);
  private readonly dataService: UserEditDataService =
    inject(UserEditDataService);

  service = inject(UserEditDataService);

  steps: string[] = [
    'step_domains',
    'step_role',
    'step_permission',
    'step_expiration',
    'step_overview',
  ];

  override ngOnInit(): void {
    super.ngOnInit();
    this.dataService.userId = this.config.data.userId;
    this.dataService.userInformationsToEdit = {
      domains: this.config.data.domains,
      permissions: this.config.data.permissions,
      role: this.config.data.role,
      expirationDate: this.config.data.expirationDate,
    };
  }
}

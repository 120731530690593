import { Injectable } from '@angular/core';
import { Domain, UserFromBackend } from '../types';
import { AbstractService } from './abstract.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WorkingDomainService extends AbstractService<Domain> {
  override readonly labelInLocalStorage = 'workingDomain';

  override getByUserFromBackend(userFromBackend: UserFromBackend): Domain {
    return userFromBackend.mainDomains[0];
  }

  domainSubject: BehaviorSubject<Domain | null> =
    new BehaviorSubject<Domain | null>(null);

  setDomainSubject(domain: Domain): void {
    this.domainSubject.next(domain);
  }

  getDomainSubjectAsValue(): Domain | null {
    return this.domainSubject.value;
  }

  getIdFromLocalStorage(): string | null {
    const domain: string = this.getFromLocalStorage();
    return domain ? String(JSON.parse(domain).id) : null;
  }

  private getWorkingDomainFromLocal(): Domain {
    const workingDomainStr: string = this.getFromLocalStorage();
    if (!workingDomainStr) throw new Error(`Working domain is not set`);
    try {
      return JSON.parse(workingDomainStr) as Domain;
    } catch (err) {
      console.error(
        `Could not parse working domain string in localStorage: parsed string: ${workingDomainStr}. ${err}`,
      );
      throw err;
    }
  }

  initDomainSubject(): void {
    this.setDomainSubject(this.getWorkingDomainFromLocal());
  }
}

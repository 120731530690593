import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from '../auth.service';
import { WorkingDomainService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private readonly authService: AuthService,
    private readonly workingDomainService: WorkingDomainService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean {
    return this.authService.validateAccess(route, (isLoggedIn) =>
      this.handleLoginStatus(isLoggedIn, state.url),
    );
  }

  private handleLoginStatus(isLoggedIn: boolean, redirectUrl: string): void {
    if (isLoggedIn) {
      this.workingDomainService.initDomainSubject();
      return;
    }

    this.authService.logOutUser(redirectUrl, true);
  }
}

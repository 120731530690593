<div class="flex h-screen overflow-hidden">
  <aside
    *ngIf="isLoggedIn"
    class="
  {{ isMenuCollapsed() ? 'w-4rem' : 'w-14rem' }}
  min-w-max
  relative z-1 shadow-2 surface-0"
  >
    <app-menu (collapse)="onMenuCollapse($event)" />
  </aside>

  <main class="relative flex flex-column flex-grow-1">
    <ng-container *ngIf="isLoggedIn">
      <app-breadcrumb class="m-2 z-1" />
    </ng-container>
    <div class="flex-grow-1 overflow-auto px-2">
      <router-outlet />
    </div>
  </main>
</div>

<p-toast
  position="top-right"
  key="newVersion"
  [preventOpenDuplicates]="true"
  [preventDuplicates]="true"
>
  <ng-template let-message pTemplate="message">
    <div class="flex flex-column" style="flex: 1">
      <div class="text-center">
        <h4>{{ message.summary | transloco | capitalcase }}</h4>
        <p>{{ message.detail }}</p>
      </div>
      <div class="grid p-fluid">
        <div class="col-6">
          <button
            type="button"
            pButton
            (click)="showDialog()"
            label="{{ 'app.lookAt' | transloco | capitalcase }}"
            class="p-button-secondary"
          ></button>
        </div>
        <div class="col-6">
          <button
            type="button"
            pButton
            (click)="refreshCacheAndReloadPage()"
            label="{{ 'app.apply' | transloco | capitalcase }}"
            class="p-button-success"
          ></button>
        </div>
      </div>
    </div>
  </ng-template>
</p-toast>

<p-dialog
  [(visible)]="visible"
  position="topright"
  [style]="{ width: '50vw', height: '100vh' }"
  *ngIf="latestRelease"
>
  <ng-template pTemplate="header">
    <span class="text-xl font-bold">Version {{ latestRelease.version }} </span>
  </ng-template>
  <ng-template pTemplate="footer">
    <div class="flex justify-content-end w-full">
      <button
        type="button"
        pButton
        (click)="refreshCacheAndReloadPage()"
        label="{{ 'app.apply' | transloco | capitalcase }}"
        class="p-button-success"
      ></button>
    </div>
  </ng-template>
  <div *ngFor="let change of latestRelease.changes" class="flex gap-3 py-4">
    <p-tag
      styleClass="{{ change.tag | releaseTagDecoder }}_tag"
      value="{{
        'app.' + (change.tag | releaseTagDecoder) | transloco | capitalcase
      }}"
    ></p-tag>
    <div
      class="text-base pt-1"
      *ngFor="let feature of filterFeaturesByLang(change.features)"
    >
      {{ feature.description }}
    </div>
  </div>
</p-dialog>

<form [formGroup]="userValidationForm" (ngSubmit)="submit()">
  <p-card
    header="{{ 'ur.title' | transloco | capitalcase }}
    {{ 'ur.luce' | transloco | capitalcase }}"
    [styleClass]="'max-h-screen min-h-screen'"
    [style]="{
      background: 'no-repeat right url(/assets/images/background-orange.png)'
    }"
  >
    <div class="grid formgrid">
      <div
        class="flex flex-column col-12 md:col-8 lg:col-6 xl:col-5 xl:m-5 bg-gray-300 pt-4 border-round opacity-100"
      >
        <div class="field col">
          <input
            pInputText
            type="text"
            [class]="'w-full'"
            placeholder="{{ email }}"
            disabled
          />
        </div>
        <div class="field col">
          <input
            formControlName="firstname"
            pInputText
            type="text"
            [class]="'w-full'"
            [placeholder]="'ur.firstname' | transloco | capitalcase"
            autocomplete="off"
            aria-describedby="firstname"
            autofocus
            required
          />
          <div
            *ngIf="firstname.invalid && (firstname.dirty || firstname.touched)"
          >
            <small *ngIf="firstname.errors?.['required']" class="text-red-500">
              {{ "ur.firstname_error.require" | transloco | capitalcase }}
            </small>
          </div>
        </div>
        <div class="field col">
          <input
            formControlName="lastname"
            pInputText
            type="text"
            [class]="'w-full'"
            [placeholder]="'ur.lastname' | transloco | capitalcase"
            autocomplete="off"
            aria-describedby="lastname"
            required
          />
          <div *ngIf="lastname.invalid && (lastname.dirty || lastname.touched)">
            <small
              *ngIf="lastname.errors?.['required']"
              class="mb-0 pb-0 text-red-500"
            >
              {{ "ur.lastname_error.require" | transloco | capitalcase }}
            </small>
          </div>
        </div>
        <div class="field col">
          <p-password
            formControlName="password"
            [placeholder]="'ur.password' | transloco | capitalcase"
            [toggleMask]="true"
            strongRegex="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{15,}$"
            [styleClass]="'w-full'"
            [inputStyleClass]="'w-full'"
            [autocomplete]="'off'"
            aria-describedby="'ur.password' | transloco |
              capitalcase"
            required
          >
            <ng-template pTemplate="footer">
              <p-divider></p-divider>
              <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
                <li>
                  {{
                    "ur.password_requirements.lowercase"
                      | transloco
                      | capitalcase
                  }}
                </li>
                <li>
                  {{
                    "ur.password_requirements.uppercase"
                      | transloco
                      | capitalcase
                  }}
                </li>
                <li>
                  {{
                    "ur.password_requirements.numeric" | transloco | capitalcase
                  }}
                </li>
                <li>
                  {{
                    "ur.password_requirements.non-alphanumeric"
                      | transloco
                      | capitalcase
                  }}
                </li>
                <li>
                  {{
                    "ur.password_requirements.whitespace"
                      | transloco
                      | capitalcase
                  }}
                </li>
                <li>
                  {{
                    "ur.password_requirements.minimum" | transloco | capitalcase
                  }}
                </li>
              </ul>
            </ng-template>
          </p-password>
          <div *ngIf="password.invalid && (password.dirty || password.touched)">
            <small class="text-red-500" *ngIf="password.errors?.['required']">
              {{ "ur.password_error.require" | transloco | capitalcase }}
            </small>
          </div>
        </div>
        <div class="field col">
          <p-password
            formControlName="confirmPassword"
            [placeholder]="'ur.confirm_password' | transloco | capitalcase"
            [toggleMask]="true"
            [feedback]="false"
            [styleClass]="'w-full'"
            [inputStyleClass]="'w-full'"
            aria-describedby="confirm password"
            required
          >
          </p-password>
          <small
            class="text-red-500"
            *ngIf="confirmPassword.hasError('passwordMismatch')"
          >
            {{
              "ur.password_error.password_mismatch" | transloco | capitalcase
            }}
          </small>
          <div
            *ngIf="
              confirmPassword.invalid &&
              (confirmPassword.dirty || confirmPassword.touched)
            "
          >
            <small
              class="text-red-500"
              *ngIf="confirmPassword.errors?.['required']"
            >
              {{
                "ur.password_error.confirm_password" | transloco | capitalcase
              }}
            </small>
          </div>
        </div>
        <div class="field col">
          <p-dropdown
            formControlName="selectedLanguage"
            [options]="languages"
            optionValue="id"
            optionDisabled="inactive"
            [autoDisplayFirst]="false"
            [styleClass]="'w-full'"
          >
            <ng-template let-option pTemplate="selectedItem">
              <div class="flex align-items-center gap-2">
                <img
                  *ngIf="option?.id"
                  src="/assets/images/lang/{{ option?.id }}.png"
                  alt="flag"
                />
                {{ "ur.langOption." + option?.label | transloco | capitalcase }}
              </div>
            </ng-template>
            <ng-template let-lang pTemplate="item">
              <div class="flex align-items-center gap-2">
                <img src="/assets/images/lang/{{ lang.id }}.png" alt="flag" />
                {{ "ur.langOption." + lang.label | transloco | capitalcase }}
              </div>
            </ng-template>
          </p-dropdown>
        </div>
        <div
          class="field col col-offset-6 sm:col-offset-7 md:col-offset-6 lg:col-offset-6 xl:col-offset-7 mt-4"
        >
          <button
            type="submit"
            pButton
            class="white-space-nowrap"
            [label]="'ur.validate' | transloco | capitalcase"
            [disabled]="!userValidationForm.valid"
          ></button>
        </div>
      </div>
    </div>
  </p-card>
</form>
<p-toast
  key="validationForm"
  [breakpoints]="{ '577px': { width: '100%', right: '0', left: '0' } }"
  [showTransformOptions]="'translateY(100%)'"
  [showTransformOptions]="'translateX(100%)'"
></p-toast>

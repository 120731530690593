import { UserFromBackend, UserProperty } from '../types';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export abstract class AbstractService<T> {
  readonly labelInLocalStorage: string = '';
  readonly propertyNamesInUserFromBackend: UserProperty[] = [];

  getByUserFromBackend(userFromBackend: UserFromBackend): T {
    if (this.propertyNamesInUserFromBackend.length === 1)
      return userFromBackend[this.propertyNamesInUserFromBackend[0]] as T;

    const res: T = {} as T;
    for (const propertyName of this.propertyNamesInUserFromBackend)
      (res as any)[propertyName] = userFromBackend[propertyName];

    return res;
  }

  storeInLocalStorage(value: T): void {
    const newValue = typeof value === 'string' ? value : JSON.stringify(value);
    localStorage.setItem(this.labelInLocalStorage, newValue);
  }

  getFromLocalStorage(): string {
    return localStorage.getItem(this.labelInLocalStorage) ?? '';
  }

  removeFromLocalStorage(): void {
    localStorage.removeItem(this.labelInLocalStorage);
  }
}

import { Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { Lang, Locale, UserFromBackend } from '../types';
import { AbstractService } from './abstract.service';
import { LocaleService } from 'src/app/services/locale.service';

@Injectable({
  providedIn: 'root',
})
export class LangService extends AbstractService<Lang> {
  override readonly labelInLocalStorage = 'translocoLang';
  private readonly locales = ['en_US', 'fr_FR'] as const;

  constructor(
    private readonly translocoService: TranslocoService,
    private readonly localeService: LocaleService,
  ) {
    super();
  }

  override getByUserFromBackend(userFromBackend: UserFromBackend): Lang {
    return this.getLangFromLocale(userFromBackend.locale);
  }

  override removeFromLocalStorage(): void {
    localStorage.removeItem(this.labelInLocalStorage);
  }

  private getLangFromLocale(locale: Locale): Lang {
    return locale.split('_')[0] as Lang;
  }

  private setActiveLang(lang: Lang): void {
    this.translocoService.setActiveLang(lang);
  }

  adjustSettings(locale: Locale): void {
    const lang = this.getLangFromLocale(locale);
    if (this.locales.includes(locale)) {
      this.setActiveLang(lang);
      this.localeService.setLocale(locale);
    } else this.setActiveLang(this.translocoService.getDefaultLang() as Lang);
  }
}

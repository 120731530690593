import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { CardModule } from 'primeng/card';
import { TagModule } from 'primeng/tag';
import { TimelineModule } from 'primeng/timeline';
import { ApplicationPipesModule } from 'src/app/app-pipes.module';
import { ChangeLogRoutingModule } from './changelog-routing.module';
import { ChangelogComponent } from './changelog.component';

const primeNG = [CardModule, TagModule, TimelineModule];

@NgModule({
  declarations: [ChangelogComponent],
  imports: [
    ApplicationPipesModule,
    CommonModule,
    TranslocoModule,
    ChangeLogRoutingModule,
    ...primeNG,
  ],
  exports: [ChangelogComponent],
})
export class ChangelogModule {}

import { CommonModule } from '@angular/common';
import { Component, Directive, Inject, OnInit } from '@angular/core';
import {
  TRANSLOCO_SCOPE,
  TranslocoScope,
  TranslocoService,
} from '@jsverse/transloco';
import { MenuItem } from 'primeng/api';
import { StepsModule } from 'primeng/steps';
import { ToastModule } from 'primeng/toast';
import { FirstLetterCapital } from 'src/app/services/first.capital.case.pipe';

export const userFormComponentParams: Component = {
  standalone: true,
  imports: [CommonModule, ToastModule, StepsModule],
};

@Directive()
export abstract class UserFormComponent<Service> implements OnInit {
  protected abstract steps: string[];
  protected abstract readonly service: Service;
  activeIndex: number = 0;
  translatedSteps: MenuItem[] = [];

  constructor(
    @Inject(TRANSLOCO_SCOPE) protected scope: TranslocoScope,
    protected readonly translocoService: TranslocoService,
    protected readonly firstLetterCapital: FirstLetterCapital,
  ) {}

  ngOnInit(): void {
    this.translateSteps();
  }

  onActiveIndexChange(event: number): void {
    this.activeIndex = event;
  }

  getActiveIndexChange(activeIndex: number) {
    this.activeIndex = activeIndex;
  }

  private translateSteps(): void {
    this.translocoService
      .selectTranslate(this.steps, {}, 'users', true)
      .subscribe((translatedStr: string[]) => {
        this.translatedSteps = translatedStr.map((str) => ({
          label: this.firstLetterCapital.transform(str),
        }));
      });
  }
}

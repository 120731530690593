import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, EMPTY, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { AuthService } from 'src/app/components/auth/auth.service';
import { EnvironmentLoaderService } from 'src/app/environment-loader.service';
import { TokensService } from '../services';
import { AccessTokenService } from '../services/tokens.service';
import { WorkingDomainService } from '../services/working-domain.service';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { TranslocoService } from '@jsverse/transloco';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private readonly authService: AuthService,
    private readonly envService: EnvironmentLoaderService,
    private readonly tokensService: TokensService,
    private readonly accessTokenService: AccessTokenService,
    private readonly workingDomainService: WorkingDomainService,
    private readonly messageService: MessageService,
    private readonly translocoService: TranslocoService,
    private readonly router: Router,
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<Record<string, string>>> {
    const baseUrl = this.envService.getEnvConfig().backend.baseUrl;
    const luceGatewayBaseUrl =
      this.envService.getEnvConfig().luceGatewayBackend.baseUrl;

    if (
      !request.url.startsWith(baseUrl) &&
      !request.url.startsWith(luceGatewayBaseUrl)
    ) {
      return next.handle(request);
    }

    const token = this.accessTokenService.getFromLocalStorage();

    if (token != null) {
      request = this.addAuthHeader(request);
    }
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (request.url.includes('/login/refresh')) {
          return this.handleRefreshLoginError();
        }

        if (error.status === 403) {
          return this.handle403Error(error);
        }

        if (error.status !== 401 || request.url.includes('/login')) {
          return throwError(() => error);
        } else {
          return this.handle401Error(request, next);
        }
      }),
    );
  }

  private handle403Error(error: HttpErrorResponse): Observable<never> {
    this.authService.redirectToHome();
    return throwError(() => error);
  }

  private handleRefreshLoginError(): Observable<
    HttpEvent<Record<string, string>>
  > {
    return this.authService
      .logOutUser(this.router.url, true)
      .pipe(
        switchMap(() => EMPTY as Observable<HttpEvent<Record<string, string>>>),
      );
  }

  private handle401Error(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<Record<string, string>>> {
    return this.tokensService.setRefreshToken().pipe(
      filter((token) => token !== null),
      take(1),
      switchMap(() => next.handle(this.addAuthHeader(request))),
    );
  }

  private addAuthHeader(request: HttpRequest<unknown>): HttpRequest<unknown> {
    const accessToken = this.accessTokenService.getFromLocalStorage();
    return accessToken
      ? request.clone({
          setHeaders: {
            Authorization: `Bearer ${accessToken}`,
            ...(this.workingDomainService.getIdFromLocalStorage() && {
              'x-bh-domain':
                this.workingDomainService.getIdFromLocalStorage() as string,
            }),
          },
        })
      : request;
  }
}

import { Component, Directive, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { CalendarModule } from 'primeng/calendar';
import { ApplicationPipesModule } from 'src/app/app-pipes.module';
import { ServiceUnion } from '../types';
import { UserFormStepComponent } from '../user-form-step.component';
// import { DateTime } from 'luxon';
import { CompareDateAndTodayValidator } from 'src/app/validators/compare-date-and-today.validator';
import { DateTime } from 'luxon';

export const userExpirationComponentParams: Component = {
  selector: 'app-user-expiration',
  templateUrl:
    '../../../abstracts/steps/user-expiration/user-expiration.component.html',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CalendarModule,
    TranslocoModule,
    ApplicationPipesModule,
  ],
};

@Directive()
export abstract class AbstractUserExpirationComponent<Service>
  extends UserFormStepComponent<Service>
  implements OnInit
{
  protected abstract override service: Service;
  today = DateTime.now().plus({ day: 1 }).toJSDate();
  useraddFormGroup = new FormGroup({
    expirationDateControl: new FormControl<Date | null>(null, {
      asyncValidators: [CompareDateAndTodayValidator.dateAndTodayCompare()],
    }),
  });

  override ngOnInit() {
    super.ngOnInit();
    const service = this.service as ServiceUnion;

    if (service.formService.getForm().expirationDate) {
      this.expirationDateControl.setValue(
        service.formService.getForm().expirationDate,
      );
    }

    if (this.config.data?.userInformationsToEdit.expirationDate) {
      const selectedDateToDate = new Date(
        this.config.data.userInformationsToEdit.expirationDate,
      );
      this.expirationDateControl.setValue(selectedDateToDate);
    }
  }

  protected override fillForm(): void {
    const service = this.service as ServiceUnion;

    service.formService.setForm({
      ...service.formService.getForm(),
      expirationDate: this.expirationDateControl.value,
    });
  }

  get expirationDateControl(): FormControl {
    return this.useraddFormGroup.get('expirationDateControl') as FormControl;
  }
}

import { Injectable } from '@angular/core';
import { TranslocoMissingHandler } from '@jsverse/transloco';

@Injectable({ providedIn: 'root' })
export class CustomMissingTranslationHandler
  implements TranslocoMissingHandler
{
  handle(key: string): string {
    console.warn(`Missing translation for key: ${key}`);
    return key;
  }
}

import { CommonModule } from '@angular/common';
import { Component, Directive, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { ApplicationPipesModule } from 'src/app/app-pipes.module';
import { IUserRole } from '../../../../interfaces/users.interfaces';
import { ServiceUnion } from '../types';
import { UserFormStepComponent } from '../user-form-step.component';

export const rolesSelectorComponent: Component = {
  host: {
    'data-unique-id': Math.random().toString(),
  },
  selector: 'app-roles-selector',
  templateUrl:
    '../../../abstracts/steps/roles-selector/roles-selector.component.html',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DropdownModule,
    ApplicationPipesModule,
    TranslocoModule,
    ButtonModule,
  ],
};

@Directive()
export abstract class AbstractRolesSelectorComponent<Service>
  extends UserFormStepComponent<Service>
  implements OnInit
{
  protected abstract override service: Service;

  rolesList!: IUserRole[];
  formGroup = new FormGroup({
    roleControl: new FormControl('', [Validators.required]),
    permissionsControl: new FormControl('', [Validators.required]),
  });

  override async ngOnInit(): Promise<void> {
    super.ngOnInit();
    const service = this.service as ServiceUnion;

    this.rolesList = await this.getAllowedUserRole();
    if (service.formService.getForm().role.length > 0) {
      this.roleControl.setValue(service.formService.getForm().role);
    } else if (this.config.data?.userInformationsToEdit.role) {
      const selectedRole = this.rolesList.find(
        (role: IUserRole) =>
          role.roleAlias === this.config.data.userInformationsToEdit.role[0],
      );
      if (selectedRole) {
        this.roleControl.setValue(selectedRole.roleAlias);
        this.applyValue();
      }
    }
  }

  getAllowedUserRole(): Promise<IUserRole[]> {
    const service = this.service as ServiceUnion;
    return service.sharedDataService.retrieveUserRoleAndSubordinateRoles();
  }

  applyValue() {
    const service = this.service as ServiceUnion;
    service.formService.setForm({
      ...service.formService.getForm(),
      role: this.roleControl.value,
    });
  }

  override prevStep(): void {
    const service = this.service as ServiceUnion;
    if (service.formService.getForm().domainUnique) {
      this.activeIndex -= 2;
      this.activeIndexChange.emit(this.activeIndex);
    } else {
      super.prevStep();
    }
  }

  get roleControl(): FormControl<string> {
    return this.formGroup.get('roleControl') as FormControl;
  }

  get permissionsControl(): FormControl<string> {
    return this.formGroup.get('permissionsControl') as FormControl;
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalcase',
})
export class FirstLetterCapital implements PipeTransform {
  transform(string: string) {
    if (!string) return string;
    return capitalize(string);
  }
}

export function capitalize(string: string) {
  return string[0].toUpperCase() + string.substring(1).toLowerCase();
}

import { IFeaturePermission } from '../interfaces/IFeaturePermission';

export const PARK_R: bigint = 1n;
export const PARK_W: bigint = 2n;
export const PARK_EXPORT: bigint = 4n;
export const PARK_VALIDATION_LUCEAPP: bigint = 8n;
export const PARK_W_PARAMS: bigint = 16n;
export const PARK_W_FREQ: bigint = 32n;
export const PARK_GROUP_R: bigint = 64n;
export const PARK_GROUP_W: bigint = 128n;
export const SW_W_PARAM: bigint = 256n;
export const SW_W_FREQ: bigint = 512n;
export const ADMIN_W_DOMAIN: bigint = 1024n;
export const ADMIN_W_USER: bigint = 2048n;
export const ADMIN_R_USER: bigint = 4096n;
export const ADMIN_W_EQUIPMENT: bigint = 8192n;
export const ADMIN_W_INTERFACE: bigint = 16384n;
export const ADMIN_R_SUBSCRIPTION: bigint = 32768n;
export const ADMIN_W_SUBSCRIPTION: bigint = 65536n;
export const LUCE_ENERGY: bigint = 131072n;
export const LUCE_CONNECT_R: bigint = 262144n;
export const LUCE_CONNECT_ASTRO: bigint = 524288n;
export const LUCE_CONNECT_CPN: bigint = 1048576n;
export const LUCE_CONNECT_PROG: bigint = 2097152n;
export const LUCE_CONNECT_ASSIGNMENT: bigint = 4194304n;
export const LUCE_CONNECT_FC_URGENCY: bigint = 8388608n;
export const LUCE_CONNECT_FC: bigint = 16777216n;
export const LUCE_CONNECT_LUCEAPP: bigint = 33554432n;
export const LUCE_CONNECT_LUCEAPP_QM: bigint = 274877906944n;
export const BO_SW: bigint = 67108864n;
export const BO_MENU: bigint = 134217728n;
export const LUCENODE_SCENARIO_R: bigint = 268435456n;
export const LUCENODE_SCENARIO_W: bigint = 536870912n;
export const LUCENODE_SCENARIO_ASSIGNMENT_R: bigint = 1073741824n;
export const LUCENODE_SCENARIO_ASSIGNMENT_W: bigint = 2147483648n;
export const LUCENODE_PLANNING_R: bigint = 4294967296n;
export const LUCENODE_PLANNING_W: bigint = 8589934592n;
export const LUCENODE_LIGHTPOINT_R: bigint = 17179869184n;
export const LUCENODE_LIGHTPOINT_W: bigint = 34359738368n;
export const LUCENODE_LIGHTPOINT_EQUIPMENT_R: bigint = 68719476736n;
export const LUCENODE_LIGHTPOINT_EQUIPMENT_W: bigint = 137438953472n;

export const featurePermissions: IFeaturePermission[] = [
  { label: 'PARK_R', permission: PARK_R, parentCategory: 'PARK' },
  { label: 'PARK_W', permission: PARK_W, parentCategory: 'PARK' },
  { label: 'PARK_EXPORT', permission: PARK_EXPORT, parentCategory: 'PARK' },
  {
    label: 'PARK_VALIDATION_LUCEAPP',
    permission: PARK_VALIDATION_LUCEAPP,
    parentCategory: 'PARK',
  },
  { label: 'PARK_W_PARAMS', permission: PARK_W_PARAMS, parentCategory: 'PARK' },
  { label: 'PARK_W_FREQ', permission: PARK_W_FREQ, parentCategory: 'PARK' },
  { label: 'PARK_GROUP_R', permission: PARK_GROUP_R, parentCategory: 'PARK' },
  { label: 'PARK_GROUP_W', permission: PARK_GROUP_W, parentCategory: 'PARK' },
  {
    label: 'SW_W_PARAM',
    permission: SW_W_PARAM,
    parentCategory: 'SWITCHBOARD',
  },
  { label: 'SW_W_FREQ', permission: SW_W_FREQ, parentCategory: 'SWITCHBOARD' },
  {
    label: 'ADMIN_W_DOMAIN',
    permission: ADMIN_W_DOMAIN,
    parentCategory: 'ADMIN',
  },
  { label: 'ADMIN_W_USER', permission: ADMIN_W_USER, parentCategory: 'ADMIN' },
  { label: 'ADMIN_R_USER', permission: ADMIN_R_USER, parentCategory: 'ADMIN' },
  {
    label: 'ADMIN_W_EQUIPMENT',
    permission: ADMIN_W_EQUIPMENT,
    parentCategory: 'ADMIN',
  },
  {
    label: 'ADMIN_W_INTERFACE',
    permission: ADMIN_W_INTERFACE,
    parentCategory: 'ADMIN',
  },
  {
    label: 'ADMIN_R_SUBSCRIPTION',
    permission: ADMIN_R_SUBSCRIPTION,
    parentCategory: 'ADMIN',
  },
  {
    label: 'ADMIN_W_SUBSCRIPTION',
    permission: ADMIN_W_SUBSCRIPTION,
    parentCategory: 'ADMIN',
  },
  {
    label: 'LUCE_ENERGY',
    permission: LUCE_ENERGY,
    parentCategory: 'LUCE_ENERGY_CATEGORY',
  },
  {
    label: 'LUCE_CONNECT_R',
    permission: LUCE_CONNECT_R,
    parentCategory: 'LUCE_CONNECT',
  },
  {
    label: 'LUCE_CONNECT_ASTRO',
    permission: LUCE_CONNECT_ASTRO,
    parentCategory: 'LUCE_CONNECT',
  },
  {
    label: 'LUCE_CONNECT_CPN',
    permission: LUCE_CONNECT_CPN,
    parentCategory: 'LUCE_CONNECT',
  },
  {
    label: 'LUCE_CONNECT_PROG',
    permission: LUCE_CONNECT_PROG,
    parentCategory: 'LUCE_CONNECT',
  },
  {
    label: 'LUCE_CONNECT_ASSIGNMENT',
    permission: LUCE_CONNECT_ASSIGNMENT,
    parentCategory: 'LUCE_CONNECT',
  },
  {
    label: 'LUCE_CONNECT_FC_URGENCY',
    permission: LUCE_CONNECT_FC_URGENCY,
    parentCategory: 'LUCE_CONNECT',
  },
  {
    label: 'LUCE_CONNECT_FC',
    permission: LUCE_CONNECT_FC,
    parentCategory: 'LUCE_CONNECT',
  },
  {
    label: 'LUCE_CONNECT_LUCEAPP',
    permission: LUCE_CONNECT_LUCEAPP,
    parentCategory: 'LUCE_CONNECT',
  },
  {
    label: 'LUCE_CONNECT_LUCEAPP_QM',
    permission: LUCE_CONNECT_LUCEAPP_QM,
    parentCategory: 'LUCE_CONNECT',
  },
  { label: 'BO_SW', permission: BO_SW, parentCategory: 'BACK_OFFICE' },
  { label: 'BO_MENU', permission: BO_MENU, parentCategory: 'BACK_OFFICE' },
  {
    label: 'LUCENODE_SCENARIO_R',
    permission: LUCENODE_SCENARIO_R,
    parentCategory: 'LUCE_NODE',
  },
  {
    label: 'LUCENODE_SCENARIO_W',
    permission: LUCENODE_SCENARIO_W,
    parentCategory: 'LUCE_NODE',
  },
  {
    label: 'LUCENODE_SCENARIO_ASSIGNMENT_R',
    permission: LUCENODE_SCENARIO_ASSIGNMENT_R,
    parentCategory: 'LUCE_NODE',
  },
  {
    label: 'LUCENODE_SCENARIO_ASSIGNMENT_W',
    permission: LUCENODE_SCENARIO_ASSIGNMENT_W,
    parentCategory: 'LUCE_NODE',
  },
  {
    label: 'LUCENODE_PLANNING_R',
    permission: LUCENODE_PLANNING_R,
    parentCategory: 'LUCE_NODE',
  },
  {
    label: 'LUCENODE_PLANNING_W',
    permission: LUCENODE_PLANNING_W,
    parentCategory: 'LUCE_NODE',
  },
  {
    label: 'LUCENODE_LIGHTPOINT_R',
    permission: LUCENODE_LIGHTPOINT_R,
    parentCategory: 'LUCE_NODE',
  },
  {
    label: 'LUCENODE_LIGHTPOINT_W',
    permission: LUCENODE_LIGHTPOINT_W,
    parentCategory: 'LUCE_NODE',
  },
  {
    label: 'LUCENODE_LIGHTPOINT_EQUIPMENT_R',
    permission: LUCENODE_LIGHTPOINT_EQUIPMENT_R,
    parentCategory: 'LUCE_NODE',
  },
  {
    label: 'LUCENODE_LIGHTPOINT_EQUIPMENT_W',
    permission: LUCENODE_LIGHTPOINT_EQUIPMENT_W,
    parentCategory: 'LUCE_NODE',
  },
];

import { CommonModule } from '@angular/common';
import { Component, Directive, inject, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { MessageService, TreeNode, TreeTableNode } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ToastModule } from 'primeng/toast';
import {
  TreeTableHeaderCheckboxToggleEvent,
  TreeTableModule,
  TreeTableNodeSelectEvent,
  TreeTableNodeUnSelectEvent,
} from 'primeng/treetable';
import { ApplicationPipesModule } from 'src/app/app-pipes.module';
import { IFeaturePermission } from 'src/app/interfaces/IFeaturePermission';
import { featurePermissions } from 'src/app/services/feature-permissions.service';
import { FirstLetterCapital } from 'src/app/services/first.capital.case.pipe';
import { IUserPermission } from '../../../../interfaces/users.interfaces';
import { ServiceUnion } from '../types';
import { UserFormStepComponent } from '../user-form-step.component';
import { InputTextModule } from 'primeng/inputtext';

interface ICols {
  field: string;
  header?: string;
  description?: string;
}

export const permissionsSelectorComponentParams: Component = {
  selector: 'app-permissions-selector',
  templateUrl:
    '../../../abstracts/steps/permissions-selector/permissions-selector.component.html',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    TreeTableModule,
    ApplicationPipesModule,
    TranslocoModule,
    CommonModule,
    ButtonModule,
    ToastModule,
    InputTextModule,
  ],
};

@Directive()
export abstract class AbstractPermissionsSelectorComponent<Service>
  extends UserFormStepComponent<Service>
  implements OnInit
{
  private readonly firstLetterCapital = inject(FirstLetterCapital);
  private readonly messageService = inject(MessageService);
  protected abstract override service: Service;

  serializedPermissions!: bigint;
  permissionsTreeNodes: TreeTableNode[] = [];
  selectedPermissionsTreeNodes: TreeTableNode | TreeTableNode[] | null = [];
  permissionCols: ICols[] = [];

  formGroup = new FormGroup({
    permissionsControl: new FormControl(null, [Validators.required]),
  });

  override async ngOnInit() {
    super.ngOnInit();
    this.permissionCols = [{ field: 'label', description: 'description' }];
    this.serializedPermissions = await this.getBigIntPermissionsByRoleAlias();

    await this.initPermissions();
  }

  async getBigIntPermissionsByRoleAlias(): Promise<bigint> {
    const service = this.service as ServiceUnion;
    const roleAlias: string = service.formService.getForm().role;
    const result: IUserPermission =
      await service.sharedDataService.getPermissionsByRole(roleAlias);
    return BigInt(result?.permissions ?? 0);
  }

  decodeBigInt(serializedPermissions: bigint): bigint[] {
    const permissions: bigint[] = [];
    for (
      let permission: bigint = BigInt(1);
      permission <= serializedPermissions && permission > 0;
      permission <<= BigInt(1)
    ) {
      if (permission & serializedPermissions) permissions.push(permission);
    }
    return permissions;
  }

  async getfeatureByPermissions(): Promise<bigint[]> {
    const permissions: bigint = await this.getBigIntPermissionsByRoleAlias();
    return this.decodeBigInt(permissions);
  }

  filterFeaturesByPermission(permissions: bigint[]): IFeaturePermission[] {
    return featurePermissions.filter((feature: IFeaturePermission) =>
      permissions.includes(feature.permission),
    );
  }

  getTreeNodeByCategory(parentCategory: string): TreeNode {
    return {
      data: {
        label: parentCategory,
      },
      children: [],
    };
  }

  addFeatureToCategory(category: TreeNode, feature: IFeaturePermission): void {
    if (category.children) {
      category.children.push({
        data: {
          label: feature.label,
          permmission: BigInt(feature.permission),
        },
      });
    }
  }

  getPermissionTreeNodesFromFeatures(
    features: IFeaturePermission[],
  ): TreeNode[] {
    const categoryMap: Map<string, TreeNode> = new Map();

    for (const feature of features) {
      const parentCategory: string = feature.parentCategory;
      if (!categoryMap.has(parentCategory)) {
        categoryMap.set(
          parentCategory,
          this.getTreeNodeByCategory(parentCategory),
        );
      }

      const category: TreeNode | undefined = categoryMap.get(parentCategory);
      if (category) {
        this.addFeatureToCategory(category, feature);
      }
    }

    return Array.from(categoryMap.values());
  }

  getSerializedPermissions(selectedPermissionsValues: bigint[]): bigint | null {
    if (selectedPermissionsValues && selectedPermissionsValues.length > 0) {
      return selectedPermissionsValues.reduce((acc: bigint, curr: bigint) => {
        return acc + curr;
      }, BigInt(0n));
    }
    return null;
  }

  getPermissionValuesFromTreeNode(permissions: TreeNode[]): bigint[] {
    return permissions
      .map((permission: TreeNode) => permission.data.permmission)
      .filter((permission) => permission !== undefined);
  }

  getPermissionsTreeNodesInParentTreeNode(category: TreeNode[]): TreeNode[] {
    return category.flatMap((parent) =>
      parent.children ? [parent, ...parent.children] : [parent],
    );
  }

  async initPermissions(): Promise<void> {
    const permissions: bigint[] = await this.getfeatureByPermissions();
    let selectedPermissions: bigint[] = [];

    await this.loadPermissions();
    const filteredFeatures: IFeaturePermission[] =
      this.filterFeaturesByPermission(permissions);

    this.permissionsTreeNodes =
      this.getPermissionTreeNodesFromFeatures(filteredFeatures);

    const service = this.service as ServiceUnion;
    if (
      this.config.data?.userInformationsToEdit.permissions &&
      this.config.data?.userInformationsToEdit.role[0] ===
        service.formService.getForm().role
    ) {
      selectedPermissions = this.decodeBigInt(
        BigInt(this.config.data.userInformationsToEdit.permissions),
      );
      const filteredFeatures: IFeaturePermission[] =
        this.filterFeaturesByPermission(selectedPermissions);

      const permissionsTreeNodes =
        this.getPermissionTreeNodesFromFeatures(filteredFeatures);

      this.selectedPermissionsTreeNodes =
        this.getPermissionsTreeNodesInParentTreeNode(permissionsTreeNodes);
    } else {
      this.selectedPermissionsTreeNodes =
        this.getPermissionsTreeNodesInParentTreeNode(this.permissionsTreeNodes);
    }
  }

  isAllPermissionsChecked(): boolean {
    return this.permissionsTreeNodes.every(
      (node: TreeTableNode) =>
        Array.isArray(this.selectedPermissionsTreeNodes) &&
        this.selectedPermissionsTreeNodes.some(
          (selectedNode) => selectedNode === node,
        ),
    );
  }

  updateSelectedPermissions() {
    if (!this.selectedPermissionsTreeNodes) {
      this.permissionsControl.setValue('0');
      return;
    }

    const selectedPermissions: bigint[] = this.getPermissionValuesFromTreeNode(
      Array.isArray(this.selectedPermissionsTreeNodes)
        ? this.selectedPermissionsTreeNodes
        : [this.selectedPermissionsTreeNodes],
    );

    const serializedPermissions: bigint | null =
      this.getSerializedPermissions(selectedPermissions);

    this.permissionsControl.setValue(serializedPermissions?.toString() ?? '0');
  }

  onHeaderCheckboxToggleAllPermssions(
    event: TreeTableHeaderCheckboxToggleEvent,
  ): void {
    const summary = event.checked
      ? this.firstLetterCapital.transform(
          this.translocoService.translate('u.messages.all_selected'),
        )
      : this.firstLetterCapital.transform(
          this.translocoService.translate('u.messages.all_unselected'),
        );

    const severity = event.checked ? 'success' : 'info';

    const showToast = () =>
      this.messageService.add({
        key: 'userForm',
        severity,
        summary,
      });

    this.updateSelectedPermissions();
    showToast();
  }

  handlePermission(
    event: TreeTableNode,
    action: 'add' | 'remove',
    severity: 'success' | 'info',
  ): void {
    this.updateSelectedPermissions();
    this.messageService.add({
      key: 'userForm',
      severity: severity,
      summary: this.firstLetterCapital.transform(
        this.translocoService.translate(
          `u.messages.${action === 'add' ? 'selected' : 'unselected'}`,
        ),
      ),
      detail: this.firstLetterCapital.transform(
        this.translocoService.translate(
          'u.permissions.' + event.node?.data.label,
        ),
      ),
    });
  }

  permissionSelect(event: TreeTableNodeSelectEvent): void {
    this.handlePermission(event, 'add', 'success');
  }

  permissionUnselect(event: TreeTableNodeUnSelectEvent): void {
    this.handlePermission(event, 'remove', 'info');
  }

  private async loadPermissions() {
    const service = this.service as ServiceUnion;

    const permissions = await service.sharedDataService.getPermissionsByRole(
      service.formService.getForm().role,
    );
    if (service.formService.getForm().permissions) {
      this.permissionsControl.setValue(
        service.formService.getForm().permissions,
      );
    } else {
      this.permissionsControl.setValue(permissions?.permissions ?? null);
    }
  }

  protected fillForm(): void {
    const service = this.service as ServiceUnion;
    this.updateSelectedPermissions();

    const permissions = this.permissionsControl.value;
    service.formService.setForm({
      ...service.formService.getForm(),
      permissions,
    });
  }

  get permissionsControl(): FormControl<string> {
    return this.formGroup.get('permissionsControl') as FormControl;
  }
}

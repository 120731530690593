<form [formGroup]="formGroup">
  <div class="flex flex justify-content-center w-full pt-8">
    <span class="p-float-label">
      <p-dropdown
        id="role"
        formControlName="roleControl"
        [options]="rolesList"
        optionLabel="roleAlias"
        optionValue="roleAlias"
        [showClear]="true"
        styleClass="w-20rem"
        (onChange)="applyValue()"
        appendTo="body"
      >
        <ng-template let-item pTemplate="item">
          <div>
            {{ "u.roles." + item.roleAlias | transloco | capitalcase }}
          </div>
        </ng-template>
        <ng-template let-roleControl pTemplate="selectedItem">
          <div *ngIf="roleControl">
            {{ "u.roles." + roleControl.roleAlias | transloco | capitalcase }}
          </div>
        </ng-template>
      </p-dropdown>
      <label for="role">
        {{ "u.role_placeholder" | transloco | capitalcase }}
      </label>
    </span>
  </div>
  <div class="flex justify-content-between w-full pt-4">
    <p-button
      label="{{ 'u.back' | transloco | capitalcase }}"
      (onClick)="prevStep()"
      icon="pi pi-angle-left"
    ></p-button>
    <p-button
      label="{{ 'u.next' | transloco | capitalcase }}"
      icon="pi pi-angle-right"
      iconPos="right"
      (onClick)="nextStep()"
      [disabled]="!roleControl.value"
    ></p-button>
  </div>
</form>

import { Component, inject } from '@angular/core';
import {
  AbstractDomainsSelectorComponent,
  domainsSelectorComponent,
} from '../../../abstracts/steps/domains-selector/domains-selector.component';
import { UserAddService } from '../../user-add.service';

const { selector, templateUrl, standalone, imports } = domainsSelectorComponent;

@Component({
  selector: selector + '[data-service="UserAddService"]',
  templateUrl,
  standalone,
  imports,
})
export class DomainsSelectorComponent extends AbstractDomainsSelectorComponent<UserAddService> {
  protected service = inject(UserAddService);
}

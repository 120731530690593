import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
  HttpContextToken,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { TranslocoService } from '@jsverse/transloco';
import { MessageService } from 'primeng/api';
import { FirstLetterCapital } from 'src/app/services/first.capital.case.pipe';

export const IS_ERROR_NOTIFICIATION_TRIGGERED = new HttpContextToken<boolean>(
  () => true,
);

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private messageService: MessageService,
    private readonly translocoService: TranslocoService,
    private readonly firstLetterCapital: FirstLetterCapital,
  ) {}

  intercept<T>(
    request: HttpRequest<T>,
    next: HttpHandler,
  ): Observable<HttpEvent<T>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (request.context.get(IS_ERROR_NOTIFICIATION_TRIGGERED) === true) {
          this.messageService.add({
            severity: 'error',
            summary: this.firstLetterCapital.transform(
              this.translocoService.translate('he.' + error.status),
            ),
            detail: error.error.bhCode
              ? `Code: ${error.error.bhCode}`
              : undefined,
          });
        }
        return throwError(() => error);
      }),
    );
  }
}

<div class="flex pt-2">
  <p-dropdown
    #workingDomainDropdown
    [options]="mainDomains"
    optionLabel="label"
    [filter]="mainDomains.length > 20"
    filterBy="label"
    appendTo="body"
    class="w-full"
    [style]="{ minWidth: '100%', width: '100%' }"
  ></p-dropdown>
  <p-button
    class="p-button-primary pl-2"
    (onClick)="selectWorkingDomain(workingDomainDropdown.selectedOption)"
    label="OK"
  >
  </p-button>
</div>

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  OnDestroy,
  OnInit,
  Output,
  signal,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  provideTranslocoScope,
  TranslocoModule,
  TranslocoService,
} from '@jsverse/transloco';
import { ButtonModule } from 'primeng/button';
import { Subscription, take } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { PermissionService } from 'src/app/services/permissions.service';
import { ApplicationPipesModule } from '../../app-pipes.module';
import { AuthService } from '../auth/auth.service';
import { Country, NavLink } from './menu.types';
import { OverlayPanelModule } from 'primeng/overlaypanel';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  providers: [provideTranslocoScope({ scope: 'menu', alias: 'm' })],
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    TranslocoModule,
    ApplicationPipesModule,
    OverlayPanelModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent implements OnInit, OnDestroy {
  private readonly translocoService = inject(TranslocoService);
  private readonly authService = inject(AuthService);
  private readonly appService = inject(AppService);
  private readonly permissionService = inject(PermissionService);
  private readonly router = inject(Router);

  @Output() collapse: EventEmitter<boolean> = new EventEmitter<boolean>();

  version!: string;

  readonly isCollapsed = signal<boolean>(true);

  private readonly subscription: Subscription = Subscription.EMPTY;

  readonly navLinks: NavLink[] = [
    {
      label: 'park',
      icon: 'pi pi-map',
      routerLink: '/park',
      visible: this.permissionService.hasPermission(['PARK_R']),
    },
    {
      label: 'lucenode',
      icon: 'pi pi-chart-bar',
      routerLink: '/lucenode/scenario',
      visible: this.permissionService.hasPermission(['LUCENODE_SCENARIO_R']),
    },
    {
      label: 'users',
      icon: 'pi pi-users',
      routerLink: '/users',
      visible: this.permissionService.hasPermission(['ADMIN_R_USER']),
    },
    {
      label: 'changelog',
      icon: 'pi pi-list',
      routerLink: '/release',
      visible: true,
    },
  ];

  ngOnInit(): void {
    this.version = this.appService.appVersion;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  toggleMenu() {
    this.isCollapsed.update((collapsed) => !collapsed);
    this.collapse.emit(this.isCollapsed());
  }

  isRouteActive(route: string) {
    return this.router.url.includes(route);
  }

  changeLanguage({ language }: Country) {
    this.subscription.add(
      this.translocoService
        .load(language)
        .pipe(take(1))
        .subscribe(() => {
          this.translocoService.setActiveLang(language);
        }),
    );
  }

  navigateTo(routerLink: string): void {
    this.router.navigate([routerLink]);
  }

  logOut() {
    this.authService.logOutUser();
  }
}

import { Injectable } from '@angular/core';
import { Domain, UserProperty } from '../types';
import { AbstractService } from './abstract.service';

@Injectable({
  providedIn: 'root',
})
export class MainDomainsService extends AbstractService<Domain[]> {
  override readonly labelInLocalStorage = 'mainDomains';
  override readonly propertyNamesInUserFromBackend: UserProperty[] = [
    'mainDomains',
  ];
}

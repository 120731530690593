<div class="w-full">
  <div class="flex flex-column gap-2 pt-8 pl-4">
    <div class="grid">
      <div class="col-4" *ngIf="email">
        <div class="flex flex-wrap gap-2 pl-2">
          <span class="text-gray-400">
            {{ "u.overview.email" | transloco | capitalcase }}:
          </span>
          <span class="font-bold">
            {{ email }}
          </span>
        </div>
      </div>
      <div class="col-4">
        <div class="flex flex-wrap gap-2">
          <span class="text-gray-400">
            {{ "u.overview.role" | transloco | capitalcase }}:
          </span>
          <span class="font-bold">
            {{ "u.roles." + role | transloco | capitalcase }}
          </span>
        </div>
      </div>
      <div class="col-4">
        <div class="flex flex-wrap gap-2" *ngIf="expirationDate !== null">
          <span class="text-gray-400">
            {{ "u.overview.expiration" | transloco | capitalcase }}:
          </span>
          <span class="font-bold">
            {{ expirationDate | date: "dd/MM/yy" }}
          </span>
        </div>
      </div>
    </div>
    <div class="grid">
      <div class="col-12 flex flex-wrap gap-2">
        <span class="text-gray-400 col-3">
          {{ "u.overview.domains" | transloco | capitalcase }}:
        </span>
        <div *ngFor="let domainList of domainsResumeList" class="w-full">
          <p-tree [value]="[domainList]" layout="horizontal" class="w-full">
            <ng-template let-node pTemplate="default">
              <strong>{{ node.data.label }}</strong>
            </ng-template>
          </p-tree>
        </div>
      </div>
    </div>
    <div class="grid">
      <div class="col-12 flex flex-wrap gap-2">
        <span class="text-gray-400 col-3">
          {{ "u.overview.permissions" | transloco | capitalcase }}:
        </span>

        <p-tree
          [value]="permissionsSummaryTreeNodes"
          scrollHeight="200px"
          class="w-full"
        >
          <ng-template let-node pTemplate="default">
            <strong>{{
              "u.permissions." + node.data.label | transloco | capitalcase
            }}</strong>
          </ng-template>
        </p-tree>
      </div>
    </div>
  </div>
</div>
<div class="flex justify-content-between w-full pt-4">
  <p-button
    label="{{ 'u.back' | transloco | capitalcase }}"
    (onClick)="prevStep()"
    icon="pi pi-angle-left"
  ></p-button>
  <p-button
    label="{{ 'u.submit' | transloco | capitalcase }}"
    (onClick)="submit()"
  >
  </p-button>
</div>

<p-toast
  key="userForm"
  [breakpoints]="{ '577px': { width: '100%', right: '0', left: '0' } }"
  [showTransformOptions]="'translateY(100%)'"
  [showTransformOptions]="'translateX(100%)'"
></p-toast>

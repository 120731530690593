import { Component, inject } from '@angular/core';
import {
  AbstractOverviewComponent,
  overviewComponentParams,
} from '../../../abstracts/steps/overview/overview.component';
import { UserInformationsToEdit } from '../../user-edit-data.service';
import { UserEditService } from '../../user-edit.service';

const { selector, templateUrl, standalone, imports } = overviewComponentParams;
@Component({
  selector: selector + '[data-service="UserEditService"]',
  templateUrl,
  standalone,
  imports,
})
export class OverviewComponent extends AbstractOverviewComponent<UserEditService> {
  protected service = inject(UserEditService);

  protected async submit(): Promise<void> {
    const service = this.service as UserEditService;
    const { domains, role, permissions, expirationDate } =
      service.formService.getForm();
    const userInformations: UserInformationsToEdit = {
      domains,
      role,
      permissions: permissions + 'n',
      expirationDate: expirationDate ?? undefined,
    };
    const userId = this.service.dataService.userId;
    const editedUser = await service.dataService.submitForm({
      userId,
      userInformationsToEdit: userInformations,
    });
    if (!editedUser) return;
    this.messageService.add({
      severity: 'success',
      summary: this.translocoService.translate('u.success'),
      detail: this.translocoService.translate('u.success_detail'),
    });
    this.ref.close(editedUser);
  }
}

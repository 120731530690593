import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable, tap, catchError, throwError } from 'rxjs';
import { EnvironmentLoaderService } from 'src/app/environment-loader.service';
import { Tokens, UserFromBackend } from '../types';
import { AbstractService } from './abstract.service';

@Injectable({
  providedIn: 'root',
})
export class RefreshTokenService extends AbstractService<string> {
  override readonly labelInLocalStorage = 'refreshToken';
}

@Injectable({
  providedIn: 'root',
})
export class AccessTokenService extends AbstractService<string> {
  override readonly labelInLocalStorage = 'accessToken';
}

@Injectable({
  providedIn: 'root',
})
export class TokensService extends AbstractService<Tokens> {
  private readonly baseUrl: string =
    this.envService.getEnvConfig().backend.baseUrl;

  constructor(
    private readonly envService: EnvironmentLoaderService,
    private readonly http: HttpClient,
    private readonly jwtHelper: JwtHelperService,
    readonly accessToken: AccessTokenService,
    readonly refreshToken: RefreshTokenService,
  ) {
    super();
  }

  override getByUserFromBackend(userFromBackend: UserFromBackend): Tokens {
    return {
      accessToken: userFromBackend.accessToken,
      refreshToken: userFromBackend.refreshToken,
    };
  }

  override storeInLocalStorage(tokens: Partial<Tokens>): void {
    if (tokens?.accessToken)
      this.accessToken.storeInLocalStorage(tokens.accessToken);
    if (tokens?.refreshToken)
      this.refreshToken.storeInLocalStorage(tokens.refreshToken);
  }

  override removeFromLocalStorage(): void {
    this.accessToken.removeFromLocalStorage();
    this.refreshToken.removeFromLocalStorage();
  }

  setRefreshToken(): Observable<Pick<Tokens, 'accessToken'>> {
    return this.refreshAccessToken().pipe(
      tap((tokens) => this.storeInLocalStorage(tokens)),
      catchError((err) => {
        return throwError(() => err);
      }),
    );
  }

  refreshAccessToken(): Observable<Pick<Tokens, 'accessToken'>> {
    const refreshToken = this.refreshToken.getFromLocalStorage();
    return this.http.post<Tokens>(`${this.baseUrl}/login/refresh`, {
      refreshToken,
    });
  }

  tokenExpirationDate(token: string): Date | null {
    return this.jwtHelper.getTokenExpirationDate(token);
  }

  isTokenExpired(token: string): boolean {
    const tokenExpiration = this.tokenExpirationDate(token);
    if (!tokenExpiration) return true;
    if (tokenExpiration.getTime() > Date.now()) return false;
    return true;
  }
}
